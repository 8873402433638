<script setup>
import { ref, onBeforeMount, computed } from 'vue';
import { router } from '../router/router.js';
import { useAQStore } from '../stores/aq.store.js';
import { useAuthStore } from '../stores/auth.store.js';
import Search  from '../Components/Search.vue';
import LowenTable from '../Components/LowenTable.vue';
import { authHeader } from '../router/authheader.js';
import Loading from '../Components/Loading.vue';

const baseUrl = process.env.APIURL + "/AQ";
const loading = ref(true);
const showEmptyToggleText = computed(() => {
    if (showEmptyTables.value) {
        return "Hide Empty";
    }
    return "Show Empty";
})
const currentAQ = ref(useAQStore().getCurrentAQ);
const tableData = ref();
const showEmptyTables = ref(false);
onBeforeMount(async() => {
    // console.log("Getting table data");
    const tableData = await getTableData();

    if (tableData) {
        loading.value = false;
    }
});

const getTableData = async () => {
    const user = useAuthStore().user;
    const currentPO = useAQStore().currentPO;
    // console.log("userId in AQ: ", user.uID);
    // console.log("currentPO in AQ: ", currentPO);
    const url = baseUrl + "/GetByPO";
    let returnValue = "";
    let call = {
        "ID": user.uID,
        "PO": currentPO
    };
    const response = await fetch(url, {
        method: 'POST',
        headers: authHeader(url),
        body: JSON.stringify(call)
    });

    returnValue = await response.json();
    // console.log("getByPO Response: ", returnValue)
    let data = returnValue.Data;

    // map the data
    aqData.value = data[0] // aqData in the response
    // console.log("AQ Data: ", aqData.value);
    tableHeaders.value = data[1]; // tableHeaders in the response
    Additional.value = data[2]; // Additional in the response
    cancelled.value = data[3]; // cancelled in the response
    pending.value = data[4]; // pending in the response
    completedPendingLowenApproval.value = data[5]; // completedPendingLowenApproval in the response
    completedPendingCustomerApproval.value = data[6]; // completedPendingCustomerApproval in the response
    readyToInvoice.value = data[7]; // readyToInvoice in the response
    invoiceSubmittedWaitingForApproval.value = data[8]; // invoiceSubmittedWaitingForApproval in the response
    installerInvoiced.value = data[9]; // installerInvoiced in the response

    // console.log("Table Data value: ", data)
    return data;
}


const searchText = ref('');
const toggleShowTable = (tableName) => {
    let button = document.getElementById(tableName + '-toggle');
    if (button.classList.contains('show-icon')) {
        button.classList.remove('show-icon');
        button.classList.add('hide-icon');
    } else {
        button.classList.remove('hide-icon');
        button.classList.add('show-icon');
    }
}

const aqData = ref();
// individual tables
const tableHeaders = ref()
const tableNames = ref([
    'Additional', 
    'Cancelled Installation', 
    'Pending Installation / Removal', 
    'Completed, Pending Lowen Approval', 
    'Completed, Pending Customer Approval', 
    'Ready to Invoice',
    'Invoice Submitted, Waiting for Approval',
    'Installer Invoiced'
])
const Additional = ref();
const cancelled = ref()
const pending = ref()
const completedPendingLowenApproval = ref()
const completedPendingCustomerApproval = ref()
const readyToInvoice = ref()
const invoiceSubmittedWaitingForApproval = ref()
const installerInvoiced = ref()


const getTable = ((tableName) => {
    if (tableName === 'Additional') {
        return Additional.value
    }
    if (tableName === 'Cancelled Installation') {
        return cancelled.value;
    }
    if (tableName === 'Pending Installation / Removal') {
        return pending.value;
    }
    if (tableName === 'Completed, Pending Lowen Approval') {
        return completedPendingLowenApproval.value;
    }
    if (tableName === 'Completed, Pending Customer Approval') {
        return completedPendingCustomerApproval.value;
    }
    if (tableName === 'Ready to Invoice') {
        return readyToInvoice.value;
    }
    if (tableName === 'Invoice Submitted, Waiting for Approval') {
        return invoiceSubmittedWaitingForApproval.value;
    }
    if (tableName === 'Installer Invoiced') {
        return installerInvoiced.value;
    }
})

const getTableCount = ((tableName) => {
    if (tableName === 'Additional' && Additional.value) {
        return Additional.value.length
    }
    if (tableName === 'Cancelled Installation' && cancelled.value) {
        return cancelled.value.length;
    }
    if (tableName === 'Pending Installation / Removal' && pending.value) {
        return pending.value.length;
    }
    if (tableName === 'Completed, Pending Lowen Approval' && completedPendingLowenApproval.value) {
        return completedPendingLowenApproval.value.length;
    }
    if (tableName === 'Completed, Pending Customer Approval' && completedPendingCustomerApproval.value) {
        return completedPendingCustomerApproval.value.length;
    }
    if (tableName === 'Ready to Invoice' && readyToInvoice.value) {
        return readyToInvoice.value.length;
    }
    if (tableName === 'Invoice Submitted, Waiting for Approval' && invoiceSubmittedWaitingForApproval.value) {
        return invoiceSubmittedWaitingForApproval.value.length;
    }
    if (tableName === 'Installer Invoiced' && installerInvoiced.value) {
        return installerInvoiced.value.length;
    }
})

function goBack() {
    // console.log("goBack");
    router.go(-1);
}

const formatHeader = ((header) => {
    header = header.replace(/ /g, '-');
    header = header.replace(/\//g, '');
    header = header.replace(/&/g, 'and');
    return header;
})

const getObject = ((item) => {
    // console.log("item", item);
    useAQStore().setCurrentObjectID(parseInt(item.ID));
    // console.log("currentObjectID", useAQStore().getCurrentObjectID);
    // console.log("currentAQ", useAQStore().getCurrentAQ);
    router.push({ name: 'unitInformation' });
})

const shouldShow = (tableName) => {
    if (getTableCount(tableName) > 0 || showEmptyTables) {
        return true;
    }
    return false;
}

</script>


<template>
    <Loading v-if="loading" />
    <section v-if="!loading" class="uk-container uk-margin-large-bottom">
        <div class="uk-margin-top">
            <a class="uk-text-secondary" href="#" @click="goBack"><span class="uk-text-secondary" uk-icon="icon: chevron-double-left"></span> Back</a>
        </div>

        <h2 v-if="aqData">{{ aqData.ProjectName }} OBJECT LIST</h2>
        <hr><!------------------------------------------------------------------------------------------------------------------------------------>

        <div v-if="aqData" class="project-summary-container">
            <p class="uk-margin-remove">Project Status: <span>{{ aqData.AQStatus }}</span></p>
            <p class="uk-margin-remove">Project Coordinator: <span>{{ aqData.Coordinator }}</span></p>
            <p class="uk-margin-remove">Coordinator email: <span>{{ aqData.CoordinatorEmail }}</span></p>
        </div>

        <Search v-model="searchText"/>

        <div class="uk-margin-bottom">

            <!-- toggle showing empty tables -->
            <button class="uk-button uk-button-secondary uk-margin-top" @click="showEmptyTables = !showEmptyTables">
                {{ showEmptyToggleText }}
            </button>

            <div v-for="tableName in tableNames" class="tables-container">
                <div :class="{'show' : showEmptyTables && getTableCount(tableName) == 0, 'hide' : !showEmptyTables && getTableCount(tableName) == 0}">
                    <caption class="uk-flex">
                        <p class="uk-tex-large uk-text-bold uk-margin-remove-bottom uk-margin-top">
                            <button v-if="getTableCount(tableName)" :id="tableName + '-toggle'" class="toggle-table uk-button uk-button-default uk-margin-right" 
                            :uk-toggle="'target: #' + formatHeader(tableName)"
                            @click.stop.prevent="toggleShowTable(tableName)">
                            <span class="" uk-icon="icon: triangle-down"></span>
                            </button>
                            <span>{{ tableName }} : </span>
                            <span :class="{'uk-text-danger' : getTableCount(tableName) != 0, 'uk-text-success' : tableName }">{{ getTableCount(tableName) }}</span>
                        </p> 
                    </caption>
                    <hr> <!------------------------------------------------------------------------------------------------------------------------------------>
                    
                    <LowenTable v-if="getTableCount(tableName) > 0"
                        v-model="searchText"
                        :exclude="['ID', 'ROUTE #', 'Status']"
                        :id="formatHeader(tableName)" 
                        :tableHeaders="tableHeaders" 
                        :tableData="getTable(tableName)"
                        :firstColumnController="[
                            {
                                elType: 'button', 
                                elText: 'View',
                                // icon: 'push',
                                function: getObject
                            }]">
                    </LowenTable>

                </div>
                
            </div >
        </div>

        <div class="uk-margin-large-top">
            <a class="uk-text-secondary" href="#" @click="goBack"><span class="uk-text-secondary" uk-icon="icon: chevron-double-left"></span> Back</a>
        </div>
    </section>
</template>