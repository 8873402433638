<script setup>
import {router} from '../router/router';
import { ref, onBeforeMount, onMounted } from 'vue';
import Search from '../Components/Search.vue';
import ProjectList from '../Components/ProjectList.vue';
import { useAQStore } from '../stores/aq.store.js';
import {useAuthStore} from '../stores/auth.store.js';
import {useErrorCheckStore} from '../stores/errorCheck.store.js';
import {authHeader} from '../router/authheader.js';
import AgileButton from '../Components/AgileComponents/AgileButton.vue';

const searchText = ref('');
const baseUrl = process.env.APIURL + '/Invoice'

const store = useAQStore();
let currentAQ = store.getCurrentAQObject;
let showInvoiceTool = ref(false);

let invoiceList = ref([]);

onMounted(async() => {
    currentAQ = store.getCurrentAQObject;
    // console.log("Current AQ: ", currentAQ)
    if (currentAQ == undefined){
        currentAQ = JSON.parse(localStorage.getItem('AQObject'));
    }
    if (currentAQ !== undefined) {
        currentAQ = JSON.parse(store.getCurrentAQObject);
        // console.log("Getting Invoices..", currentAQ);
        // console.log("Current AQ:", currentAQ.AQ);
        invoiceList.value = await getInvoices();
    }
})

const getInvoices = async () => {
//   console.log("Selected at click: ", props.selected);
  if (currentAQ) {
    const user = useAuthStore().user;
    const url = baseUrl + '/getInvoices';
    let returnValue = "";
    let call = {
      ID: user.uID,
      AQNumber: currentAQ.AQ
    };
    // console.log("Get Invoices call: ", call);
    // console.log("URL: ", baseUrl);
    const response = await fetch(url, {
      method: 'POST',
      headers: authHeader(url),
      body: JSON.stringify(call)
    });

    let checkResponse = useErrorCheckStore().checkStatus(response);
    if (checkResponse){
        returnValue = await response.json();
        // console.log("getInvoices Response: ", returnValue);
        invoiceList = returnValue.Data.InvoiceList;
    }
    else {
      router.push({ name: 'Login' });
    }

  }
}

const toggleInvoiceCreation = () => {
    router.push({ name: 'CreateInvoice' });
}

function goBack() {
    // console.log("goBack");
    router.go(-1);
}

</script>

<template>
    <section class="uk-container">
        <div class="uk-margin-top">
            <a class="uk-text-secondary" href="#" @click="goBack"><span class="uk-text-secondary" uk-icon="icon: chevron-double-left"></span> Back</a>
        </div>

        <div class="invoice-container uk-box-shadow-medium uk-padding">
            <h2 class="uk-text-center uk-margin-remove-top">INVOICE LIST</h2>
            <hr class="">
            <div class="invoice-summary-container uk-flex uk-flex-around uk-text-bold">
                <p class="uk-margin-remove">AQ: <span class="uk-text-normal">{{ currentAQ.AQ }}</span></p>
                <p class="uk-margin-remove">Project Coordinator: <span class="uk-text-normal"> {{ currentAQ.Coordinator }}</span></p>
                <p class="uk-margin-remove">Project Status: <span class="uk-text-normal">{{ currentAQ.AQStatus }}</span></p>
            </div>
            
            <hr class="uk-margin-large-bottom">
            <div class="uk-flex uk-flex-between uk-grid-small" uk-grid>
                <Search class="uk-width-1-1 uk-width-medium@s" :ID="'InvoiceList-Search'" v-model="searchText"/>
                <!-- <div class="uk-position-relative uk-overflow-hidden">
                    <button @click="toggleInvoiceCreation"
                            class="uk-button uk-button-primary uk-margin-bottom uk-float-right project-button-small">
                    Create Invoice
                    </button>
                </div> -->
                <div class="uk-width-medium@s uk-padding-small uk-padding-remove-right uk-padding-remove-vertical">
                    <AgileButton class="uk-width-1-1" @click="toggleInvoiceCreation" >Create Invoice</AgileButton>
                </div>
            </div>
    
            <ProjectList
                v-model="searchText"
                :ID="'InvoiceProjectList'"
                :Data="invoiceList"
                :Headers="[
                    {
                        key: 'Invoice Number',
                        display: 'Invoice #',
                        ratio: 1,  // ratio is 1 - 6, 6 representing the whole width of the parent, 1 representing 1/6th of the width of the parent/ Total between headers should = 6
                        column: 0
                    },
                    {
                        key: 'Invoice Date',
                        display: 'Date',
                        ratio: 1,
                        column: 1
                    }, 
                    {
                        key: 'Invoice Amount',
                        display: 'Amount',
                        ratio: 1,
                        column: 2
                    }, 
                    {
                        key: 'Invoice Status',
                        display: 'Status',
                        ratio: 1,
                        column: 3
                    },
                ]"
                :MobileHeaders="[
                    {
                        key: 'Invoice Number',
                        display: 'Invoice #',
                        ratio: 1,  // ratio is 1 - 4, 4 representing the whole width of the parent, 1 representing 1/4th of the width of the parent/ Total between headers should = 4
                        column: 0
                    },
                    {
                        key: 'Invoice Status',
                        display: 'Status',
                        ratio: 3,
                        column: 1
                    },
                    // {
                    //     key: 'Invoice Amount',
                    //     display: 'amount',
                    //     ratio: 1,
                    //     column: 2
                    // }
                ]"
                :ToggleKey="'InvNumber'"
                :FetchKey="'InvoiceID'"
                :EndPoint="'/Invoice/getInvoiceDetail'"
                :DataType="'Invoice'"
            />

            <div v-if="invoiceList && invoiceList.length < 1">
                <p class="uk-text-center uk-text-italic">No Invoices Found</p>
            </div>
        </div>


        <div class="uk-margin-top">
            <a class="uk-text-secondary" href="#" @click="goBack"><span class="uk-text-secondary" uk-icon="icon: chevron-double-left"></span> Back</a>
        </div>

<!--        <div class="uk-width-1-1">-->
<!--            <CreateInvoiceTool v-if="showInvoiceTool" v-model="currentAQ" :close="toggleInvoiceCreation" :getInvoices="getInvoices"/>-->
<!--        </div>-->

    </section>
</template>