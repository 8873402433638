<script setup>
import {computed, onMounted, watch, ref, onBeforeMount} from 'vue';
import {useRouter, useRoute} from 'vue-router';
import {useAuthStore} from "../stores/auth.store.js";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import {authHeader} from "../router/authheader";
import {useAQStore} from "../stores/aq.store.js";

// Data
let username = ref("");
// let explicitLogout = ref(false);
let store = useAuthStore();
let router = useRouter();
let user = ref({});
const baseUrl = process.env.APIURL + "/AppUsers";
const franchise = process.env.franchise;

//Lifecycle
onMounted(() => {
  // console.log("mounting menu");
  UIkit.use(Icons);
  // explicitLogout.value = store.getExplicitLogout();
  user.value = store.getUser();
  // console.log("user", user.value);
  // const router = useRoute();
  // if (user.value != null) {
  //   store.refreshToken();
  //   // console.log("user is not null: ", user.value);
  //   // isAuthenticated.value = true;
  // } 
  setInterval(() => {
      if (store.user) {
        // log("Token refreshed");
        store.refreshToken();
      } 
      // log("Current user is valid?: ", this.isAuthenticated);
    }, 600000) // 10 minutes
});

// watch(() => router.currentRoute.value, (to, from) => {
//   console.log("Route Changed to: ", to);
//   store.refreshToken();
// });


const isAuthenticated = computed(() => {
  return store.getUser() ? true : false;
});

// Watch the route name property for changes.
watch(() => router.name, (to, from) => {
  // console.log("route changed", to, from);
  
  if (from == undefined) {
    from = to;
    if (to == 'home' && isAuthenticated.value == true) {
      router.push('/projects');
    } else {
      checkLogin();
    }
    return;
  }
  // if (!store.getExplicitLogout()){
  //   // console.log("Just checked explicitLogout. It is: ", explicitLogout.value);
  //   // console.log("Not explicit logout");
  //   checkLogin();
  //   return;
  // }
  // console.log("Not checking login because user explicitly logged out.")
  // explicitLogout.value = false;
  // store.setExplicitLogout(false);
}
    // () => route.name,
    // check => {
    //   // console.log("check", check);
    //   checkLogin();
    // }
);
//Computed


//Methods
// function logoutClicked() {
//   // console.log("logout");
//   // console.log("Setting explicit logout to true");
//   // isAuthenticated.value = false;
//   store.setExplicitLogout(true);
//   // console.log("Did it set to true?: ", store.getExplicitLogout());
//   let authStore = useAuthStore();
//   store.logout();
// }

function logout() {
  // let authStore = useAuthStore();
  store.logout();
}

function checkLogin() {
  // log("TheMenu checkLogin", user);
  // isAuthenticated.value = false;
  // If we have a userId we can getCartItemCounts
  if (user.value) {
    // log("Menu has user", user);
    username.value = user.Username;
    // isAuthenticated.value = true;
  } else {
    // isAuthenticated.value = false;
  }
}

</script>
<template>
  <div class="top-toolbar uk-padding uk-padding-remove-vertical uk-box-shadow-medium">
    <div v-if="isAuthenticated" class="">
      <!-- desktop menu -->
      <nav class="main-menu uk-navbar uk-container uk-visible@m uk-padding-small uk-padding-remove-vertical" uk-navbar="mode: click">
        <div class="uk-navbar-left">
            <router-link class="uk-navbar-item uk-margin-top uk-logo uk-display-inline-block" :to="{name: 'projects'}">
               <img class="cg-logo" src="../../public/LowenOval_blue.png"/>
            </router-link>
            <ul class="uk-navbar-nav">
            <li v-if="isAuthenticated">
              <router-link class="uk-logo uk-margin-left" :to="{name: 'projects'}">
                Projects
              </router-link>
            </li>
            <li v-if="isAuthenticated && useAQStore().currentObjectID">
              <router-link class="uk-logo uk-margin-left" :to="{name: 'unitInformation'}">
                Location Info
              </router-link>
            </li>
          </ul>
        </div>
        <div class="uk-navbar-right">
          <ul class="uk-navbar-nav">
            <li v-if="isAuthenticated">
              <router-link :to="{name: 'Profile'}">
                <span uk-icon="icon: list"></span><span>Profile</span>
              </router-link>
            </li>
            <li v-if="isAuthenticated" class="uk-margin-right">
              <a to="#" @click="logout"><span uk-icon="lock"></span>Logout</a>
            </li>
          </ul>
        </div>
      </nav>
      <nav class="uk-hidden@m main-menu uk-flex uk-flex-middle uk-flex-center uk-flex-between uk-padding uk-padding-remove-vertical " uk-dropnav="mode: click; offset: 30">
        <router-link class="uk-navbar-item uk-margin-top uk-logo uk-display-inline-block" :to="{name: 'projects'}">
            <img class="cg-logo" src="../../public/LowenOval_blue.png"/>
        </router-link>
        <a class="mobile-menu-toggle mobile-grid">
          <div class="square"></div>
          <div class="square"></div>
          <div class="square"></div>
          <div class="square"></div>
        </a>
        <div class="uk-dropdown uk-background-primary uk-width-1-1 mobile-drop-menu" uk-dropdown="mode: click; pos: bottom-center; offset: 30" >
          <ul class=" uk-nav uk-dropdown-nav uk-flex uk-child-width-1-2 uk-width-1-1 uk-card-primary" >
            <div class="uk-light">
              <li v-if="isAuthenticated">
                <router-link class="uk-margin-left uk-light" :to="{name: 'projects'}">
                  Projects
                </router-link>
              </li>
              <li v-if="isAuthenticated && useAQStore().currentProject">
                <router-link class="uk-margin-left uk-light" :to="{name: 'invoice'}">
                  Invoices
                </router-link>
              </li>
              <!-- temporary - remove after testing Should only show when user click on an object to view details -->
              <li v-if="isAuthenticated && useAQStore().currentObjectID">
                <router-link class="uk-margin-left uk-light" :to="{name: 'unitInformation'}">
                  Unit Info
                </router-link>
              </li>
              <li>
                <router-link v-if="isAuthenticated && useAQStore().currentProject" class="uk-margin-left" :to="{name: 'aqDetails'}">
                 AQ Details
                </router-link>
              </li>
            </div>
            <div>
              <li v-if="isAuthenticated">
                <router-link :to="{name: 'Profile'}" class="uk-hidden@m uk-light">
                  <span uk-icon="icon: list"></span><span>Profile</span>
                </router-link>
              </li>
              <li v-if="isAuthenticated" class="uk-hidden@m uk-margin-right">
                <a class="uk-link uk-light uk-text-default" to="#" @click="logoutClicked"><span uk-icon="lock"></span>Logout</a>
              </li>
            </div>
          </ul>
        </div>
      </nav>
    </div>
    <div v-if="!isAuthenticated" class="top-toolbar uk-container branding uk-flex uk-flex-middle uk-flex-between">
      <div class="uk-align-center uk-padding uk-padding-remove-bottom uk-hidden@m">
        <router-link class="cg-logo" :to="{name: 'home'}">
          <img src="../../public/LowenOval_blue.png"/>
        </router-link>
      </div>
      <div class="uk-align-left uk-visible@m">
        <router-link class="cg-logo uk-margin-top" :to="{name: 'home'}">
          <img src="../../public/LowenOval_blue.png"/>
        </router-link>
      </div>
      <div class="uk-visible@m">
        <h2 class="uk-h3 uk-margin-remove">CONTACT US: 800 . 835 . 2365</h2>
      </div>
    </div>
  </div>
</template>