<script setup>
const props = defineProps(['modelValue', 'Shadow', 'ID'])
const emit = defineEmits(['update:modelValue'])

const handleChange = (e) => {
    emit('update:modelValue', e.target.value)
}
</script>
<template>
  <div class="">
    <div class="">
      <input :class="{'uk-input search-input uk-text-primary' : {}, 'uk-box-shadow-medium' : props.Shadow}" type="text" aria-label="Not clickable icon"
             :value="props.modelValue"
             :id="props.ID"
             placeholder="Search"
             @input="handleChange">
    </div>
  </div>
</template>