<script setup>
import {onBeforeMount, ref, watch, computed, onMounted} from 'vue';
import {useAuthStore} from '../stores/auth.store.js';
import {useAQStore} from '../stores/aq.store.js';
import {useErrorCheckStore} from '../stores/errorCheck.store.js';
import {authHeader} from '../router/authheader.js';
import {router} from '../router/router';
import Loading from '../Components/Loading';
import InvoiceObject from '../Components/InvoiceObject.vue';
import AgileButton from '../Components/AgileComponents/AgileButton.vue';

const baseUrl = process.env.APIURL + '/Invoice'
const invoiceList = ref([]);
// displays objects for invoicing
let invoiceObjects = ref([]);
// displays groupCharges at bottom of page.
const groupCharges = ref([]);
// user entered invoice number

let invoiceNumber = ref("");
const store = useAQStore();
let currentAQ = store.getCurrentAQObject;
let selectAll = ref(false);
const myID = ref(currentAQ.AQ);
let storedGroups = [];

// Attempting to group objects and group charges
let chargeByObject = ref([]);

// selected group charges
let groupChargesPayload = ref([]);
// selected objects for invoicing
let selectedObjects = ref([]);

onMounted(async () => {
  console.log("onMounted CreateInvoice");
  currentAQ = store.getCurrentAQObject;
  // console.log("Current AQ: ", currentAQ)
  if (currentAQ == undefined) {
    currentAQ = JSON.parse(localStorage.getItem('AQObject'));
  }
  if (currentAQ !== undefined) {
    currentAQ = JSON.parse(store.getCurrentAQObject);
    // console.log("Getting Invoices..", currentAQ);
    // console.log("Current AQ:", currentAQ.AQ);
    await getInvoicingObjects();
  }
})

const getInvoicingObjects = async () => {
  console.log("getInvoicingObjects");
  if (currentAQ) {
    const user = useAuthStore().user;
    const url = baseUrl + '/getInvoicingObjects';
    let returnValue = "";
    let call = {
      ID: user.uID,
      AQNumber: currentAQ.AQ
    };
    // console.log("Get Invoicing objects call: ", call);
    const response = await fetch(url, {
      method: 'POST',
      headers: authHeader(url),
      body: JSON.stringify(call)
    });

    let checkResponse = useErrorCheckStore().checkStatus(response);
    if (checkResponse) {
      returnValue = await response.json();
      // console.log("getInvoices Response: ", returnValue);
      // invoiceList.value = returnValue.Data.InvoiceDetailsList;
      invoiceObjects.value = handleInvoiceList(returnValue.Data.InvoiceDetailsList);
      invoiceObjects.value = returnValue.Data.InvoiceDetailsList;
      groupCharges.value = returnValue.Data.GroupCharges;
      storedGroups = returnValue.Data.Groups;
      // console.log("chargeByObject", chargeByObject.value);
      console.log("done with response");
    }
  }
}

// Expand UniqueIDs to use on summary card.
const handleInvoiceList = (list) => {
  list.forEach((item) => {
    item.UniqueIDs.forEach((id) => {
      item[id.key] = id.value;
    });
  });
  return list;
}

const submitInvoice = async () => {
  console.log("submitInvoice")
  // don't let user submit an empty invoice
  if (invoiceAmount.value === 0 || invoiceAmount.value === null) {
    alert("Please select at least one object to invoice.");
    return;
  }

  // await formatGroupCharges();
  // console.log("submitInvoice Invoice Amount: ", invoiceAmount.value);
  const user = useAuthStore().user;
  const url = baseUrl + '/submitInvoice';
  let returnValue = "";
  let call = {
    UserID: user.uID,
    AQNumber: currentAQ.AQ,
    InvoiceNumber: invoiceNumber.value,
    InvoiceAmount: invoiceAmount.value,
    InvoiceObjects: selectedObjects.value,
    GroupCharges: groupChargesPayload.value,
    Groups: storedGroups
  };
  // console.log("Submit Invoice call: ", call);
  const response = await fetch(url, {
    method: 'POST',
    headers: authHeader(url),
    body: JSON.stringify(call)
  });

  let checkResponse = useErrorCheckStore().checkStatus(response);
  // console.log("Submit Invoice checkResponse", checkResponse);
  if (checkResponse) {
    returnValue = await response.json();
    let checkReturnValue = useErrorCheckStore().checkStatusJSON(returnValue, true, true);
    // console.log("Submit Invoice checkReturnValue", checkReturnValue);
    if (checkReturnValue) {
      // console.log("Submit Invoice Response: ", returnValue);
      await getInvoicingObjects();
      // router.push({ name: 'InvoiceList' });
      toInvoiceList();
    }

  }
  invoiceAmount.value = 0;
  selectedObjects.value = [];
  groupCharges.value = [];
  invoiceNumber.value = "";
}

let hideNote = localStorage.getItem('hideNote');


let invoiceAmountToFixed = computed(() => {
  let fixed = '$' + invoiceAmount.value.toFixed(2);
  return fixed;
})

const hasGroupCharges = (item) => {
  return item.ItemGroupCharges && item.ItemGroupCharges.length > 0
}
const selectObject = (data, index) => {
  // console.log("CreateInvoice selectObject Selected Object: ", data);
  if (!selectedObjects.value.includes(data)) {
    // console.log("CI add object", data);
    selectedObjects.value.push(invoiceObjects.value[index]);
    // console.log("Selected Objects: ", selectedObjects.value);
  } else {
    // console.log("CI remove object");
    selectedObjects.value.splice(selectedObjects.value.indexOf(data), 1);
    // console.log("Selected Objects: ", selectedObjects.value);
  }
}

const selectGroupCharge = (data, index) => {
  // console.log("Selected Group Charge: ", data);
  if (!groupChargesPayload.value.includes(data)) {
    // console.log("Adding Group Charge");
    // selectedObjects.value.push(groupCharges.value[index]);
    groupChargesPayload.value.push(data);
    // console.log("Selected Objects: ", selectedObjects.value);
  } else {
    // console.log("Removing Group Charge", groupChargesPayload.value.indexOf(data));
    // console.log("removing Selected Objects: ", selectedObjects.value[selectedObjects.value.indexOf(data)]);
    groupChargesPayload.value.splice(groupChargesPayload.value.indexOf(data), 1);
  }
}

const selectAllObjects = () => {
  // better way to handle this
  selectAll.value = !selectAll.value;
  selectedObjects.value = [];
  groupChargesPayload.value = [];
  if (selectAll.value) {
    // add each invoice object to selectedObjects
    invoiceObjects.value.forEach((item) => {
      selectedObjects.value.push(item);
    });
    // add each group charge to groupChargesPayload
    groupCharges.value.forEach((item) => {
      groupChargesPayload.value.push(item);
    });
    // console.log("All selected: ", selectedObjects.value);
  }
}

const invoiceAmount = computed(() => {
  // console.log("computing invoiceAmount");
  let amount = 0;
  selectedObjects.value.forEach((item) => {
    item.Charges.forEach((charge) => {
      amount += charge.ChargeAmount;
    })
    // console.log("invoice amount", amount)
  });

  groupChargesPayload.value.forEach((gc) => {
    // console.log("add group charges", gc);
    let qty = 1;
    if (gc.hasOwnProperty("ChargeQuantity")) {
      qty = gc.ChargeQuantity;
    }
    amount += (qty * gc.ChargeAmount);
  });
  // console.log("group charge amount", amount);
  return amount;
});

const rememberHide = () => {
  hideNote = !hideNote;
  localStorage.setItem('hideNote', hideNote);
}

const shouldDisableSubmit = computed(() => {
  if (invoiceAmount.value === 0 || invoiceAmount.value === null) {
    return true;
  } else {
    return false;
  }
})

const toInvoiceList = () => {
  router.push({ name: 'invoice' });
}

function goBack() {
  router.go(-1);
}
</script>

<template>
  <div class="uk-container">
    <div class="uk-margin-top">
      <a class="uk-text-secondary" href="#" @click="goBack"><span class="uk-text-secondary"
                                                                  uk-icon="icon: chevron-double-left"></span> Back</a>
    </div>
    <div :id="'InvoiceCreationTool' + myID" class="uk-padding uk-box-shadow-medium">
      <div class="uk-flex uk-flex-column uk-flex-between uk-padding-remove-top uk-position-relative">
        <div class="uk-position-relative">
          <AgileButton @click="toInvoiceList" class="uk-width-small uk-align-right uk-margin-remove">Invoice List
          </AgileButton>
        </div>
        <div class="create-invoice-heading uk-position-relative">
          <h2 v-if="currentAQ.ProjectName" class="uk-text-bold uk-text-center uk-margin-remove-top">Creating Invoice for
            {{ currentAQ.ProjectName }}</h2>
          <h2 v-else class="uk-text-bold uk-text-center uk-margin-remove-top">Creating Invoice for AQ#
            {{ currentAQ.AQ }}</h2>
          <hr class="uk-margin-remove-bottom uk-margin-small-top">
        </div>
        <div class="uk-flex uk-flex-between uk-flex-middle ">
          <div
              class="uk-position-relative uk-padding-small uk-padding-remove-horizontal uk-margin-right uk-margin-bottom">
            <AgileButton class="uk-button-small uk-width-1-1 uk-width-small@s" @click="selectAllObjects">
              <span v-if="selectAll">REMOVE ALL</span>
              <span v-else>ADD ALL</span>
            </AgileButton>
          </div>
          <div v-if="!hideNote"
               class="note-box uk-margin-top uk-flex uk-flex-between uk-flex-middle uk-position-relative uk-margin-bottom uk-flex-1">
            <span class="uk-padding-small uk-padding-remove-right uk-width-stretch">Click a tile to add it to or remove it from the invoice or click "ADD ALL" to add all tiles to the invoice.</span>
            <button @click="rememberHide" class="uk-button uk-button-emphasis close-button uk-text-lead" type="button">
              <span uk-toggle="target: .note-box; animation: slide-top; duration: 500;"
                    uk-icon="icon: eye-slash; ratio: 1.4"></span></button>
          </div>
        </div>
        <div class=" uk-margin-bottom">
          <!--          Invoice Objects-->
          <div v-if="invoiceObjects && invoiceObjects.length > 0"
               class="objects-container uk-margin-top">
            <h3>Objects Ready to Invoice</h3>
            <div v-for="(item, index) in invoiceObjects" class="uk-width-1-1">
              <div class="uk-margin"
                   :class="{'object-group-section': hasGroupCharges(item) }">
                <div class="">
                  <InvoiceObject v-model="selectAll" :caller="'invoiceCreation'"
                                 :function="() => selectObject(item, index)"
                                 :data="item"/>
                </div>
                <div v-if="hasGroupCharges(item)">
                  <h4 class="uk-margin-small-top">Additional Charges</h4>
                  <div class="group-charges-container uk-grid-small" uk-grid>
                    <div v-for="(chg, index2) in item.ItemGroupCharges" class="uk-width-1-1">
                      <InvoiceObject v-model="selectAll" :caller="'invoiceCreation'"
                                     :function="() => selectGroupCharge(chg, index2)" :data="chg"/>
                    </div>
                  </div>
                </div>
              </div>
              <hr v-if="hasGroupCharges(item)" class="uk-margin-medium uk-divider-icon">
            </div>
          </div>
          <div v-else>
            <p class="uk-text-lead">No objects ready to invoice...</p>
          </div>
          <!--          Group Charges-->
          <!--          <div v-if="groupCharges && groupCharges.length > 0">-->
          <!--            <h3 class="uk-margin-small-top">Additional Charges</h3>-->
          <!--            <div class="objects-container uk-flex uk-margin-top uk-grid-small" uk-grid>-->
          <!--              <div v-for="(item, index2) in groupCharges" class="uk-width-1-1">-->
          <!--                <InvoiceObject v-model="selectAll" :caller="'invoiceCreation'"-->
          <!--                               :function="() => selectGroupCharge(item, index2)" :data="item"/>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>

        <div class="input-container uk-position-buttom uk-flex uk-flex-bottom uk-child-width-1-1 uk-child-width-1-3@m"
             uk-grid>
          <div class="uk-margin-remove">
            <label class="uk-form-label" :for="'invoicenumber' + myID">Invoice Number</label>
            <div class="uk-form-controls">
              <input class="uk-input" :id="'invoicenumber' + myID " type="text" placeholder="Custom Invoice Number?"
                     v-model="invoiceNumber">
            </div>
          </div>
          <div class="uk-margin-remove">
            <label class="uk-form-label" :for="'invoiceamount' + myID">Invoice Amount</label>
            <div class="uk-form-controls">
              <input class="uk-input" :id="'invoiceamount'+ myID" type="text" placeholder="Input"
                     v-model="invoiceAmountToFixed" readonly>
            </div>
          </div>
          <div>
            <AgileButton class="uk-width-1-1" @click="submitInvoice" :disabled="shouldDisableSubmit">SUBMIT INVOICE
            </AgileButton>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-margin-top">
      <a class="uk-text-secondary" href="#" @click="goBack"><span class="uk-text-secondary"
                                                                  uk-icon="icon: chevron-double-left"></span> Back</a>
    </div>
  </div>
</template>