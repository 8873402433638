<!--
    Changing the button with props. Place the props in the component tag like this:
    Icons:  UIkit icons ->  icon="home"
    Icon Button: ->  :iconButton="true"
    Tool Tip: ->  toolTip="Tool Tip Text"

    Note: If you use iconButton, the text will show up but you can still use the tooltip.
-->

<script setup>
import { computed } from 'vue';
const props = defineProps({
    icon: {
        type: String,
    },
    iconButton: {
        type: Boolean,
        default: false,
    },
    toolTip: {
        type: String,
    },
});

const toolTip = computed(() => {
    let toolTip = '';
    if (props.toolTip){
        toolTip = props.toolTip;
    }
    return toolTip;
});

// manage classes
const btnClass = computed(() => {
    let btnClass = 'agile-button prevent-select uk-button-primary ';
    if (!props.iconButton){
        btnClass += ' uk-button';
    }
    if (props.iconButton){
        btnClass += ' uk-icon-button';
    }
    return btnClass;
});

</script>
<template>
    <button :class="btnClass" :uk-tooltip="toolTip">
        <slot v-if="!props.iconButton">
            Agile Button
        </slot>
        <span v-if="props.icon" :uk-icon="'icon:' + props.icon"></span>
    </button>
</template>