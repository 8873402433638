﻿<script setup>
import {useAuthStore} from '../stores/auth.store.js';
import {authHeader} from '../router/authheader';
import { ref, onMounted } from "vue";

const baseUrl = process.env.APIURL + "/Users";
const userList = ref([]);
const franchise = 'Installer';

onMounted(() => {
  // console.log("TheUserList is mounted");
  fetchAllUsers();
});
const fetchAllUsers = async () => {
      // console.log("fetchAllUsers");
      const url = baseUrl + "/GetAll";
      let response = await fetch(url, {
        method: "GET",
        headers: authHeader(url),
      });
      let checkResponse = useErrorCheckStore().checkStatus(response.status);
      if (checkResponse){
        let userResponse = await response.json();
        // console.log("fetchUserDetails: ", userResponse);
        if (userResponse.Status === "ok") {
          this.userList = userResponse.Data;
        }
        if (userResponse.Status === "exception") {
          // console.log("Could not retrieve userResponse:", userResponse.Message);
        }
      }
}
</script>

<template>
  <div class="uk-container uk-container-large">
    <div>
      <h3>Users</h3>
      <table class="uk-table uk-table-striped uk-table-middle">
        <thead>
        <th class="uk-table-shrink" ><span class="uk-h4">ID</span></th>
        <th class="uk-table-shrink"><span class="uk-h4">Customer ID</span></th>
        <th class="uk-table-shrink"><span class="uk-h4">Username</span></th>
        <th class="uk-table-expand"><span class="uk-h4">RefreshTokens</span></th>
        </thead>
        <tbody>
        <tr v-for="user in userList">
          <td>{{ user.uID }}</td>
          <td>{{ user.customerId }}</td>
          <td>{{ user.username }}</td>
          <td class="uk-text-truncate">
            <div class="uk-panel uk-panel-scrollable">
              <template v-for="t in user.refreshTokens">
                <div class="uk-alert">
                  <!--                  <div class="uk-text-truncate"><strong>Token:</strong> {{ (t.token).slice(0, 50) }}...</div>-->
                  <div class="uk-text-truncate"><strong>Token:</strong> {{ t.token }}...</div>
                  <div><strong>Created:</strong> {{ t.created }}</div>
                  <div><strong>Active:</strong> {{ t.isActive }}</div>
                  <div><strong>Revoked:</strong> {{ t.isRevoked }}</div>
                  <div><strong>Revoked Reason:</strong> {{ t.reasonRevoked }}</div>
                  <div><strong>Expires:</strong> {{ t.expires }}</div>
                  <div><strong>Expired:</strong> {{ t.isExpired }}</div>
                </div>
              </template>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div> <!--end container-->

</template>
