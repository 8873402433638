import { createApp, ref } from "vue";
import { createPinia } from "pinia";
import { router } from "./router/router";
import  InstallationApp  from "./views/InstallationApp.vue";
import GlobalErrorHandler from "./Components/GlobalErrorHandler.vue";
import AgileButton from "./Components/AgileComponents/AgileButton.vue";
import AgileInput from "./Components/AgileComponents/AgileInput.vue";
import AgileCloseOffcanvas from "./Components/AgileComponents/AgileCloseOffcanvas.vue";

globalThis.__VUE_OPTIONS_API__ = false;
globalThis.__VUE_PROD_DEVTOOLS__ = false;

const TheInstallationApp = createApp(InstallationApp);

TheInstallationApp.use(createPinia());
TheInstallationApp.use(router);

// global component registration
TheInstallationApp
    .component("GlobalErrorHandler", GlobalErrorHandler)
    .component("AgileButton", AgileButton)
    .component("AgileInput", AgileInput)
    .component("AgileCloseOffcanvas", AgileCloseOffcanvas);

console.log("mount the installation application");
TheInstallationApp.mount("#InstallationAppContainer");