<script setup>
import { ref } from "vue";
import {RouterLink, RouterView} from "vue-router";
import theMenu from "../Components/Menu";
import theFooter from "../Components/Footer";
import GlobalErrorHandler from "../Components/GlobalErrorHandler.vue";

const status = ref(0);
</script>

<template>
  <div id="PrimaryLayout">
    <div id="MenuContainer" class="uk-container uk-container-expand mainbody uk-padding-remove-top uk-padding-remove-bottom">
      <div class="" uk-sticky="position: top">
        <theMenu />
        <GlobalErrorHandler />
      </div>
    </div>
    <section id="MainBodySection"
             class="">
      <div id="MainBodyContainer" class="uk-container uk-container-expand mainbody uk-padding-remove" uk-height-viewport="expand: true">
        <RouterView/>
      </div>
    </section>

    <section id="GlobalFooter" class="uk-container uk-container-expand mainbody uk-padding-remove">
      <!--      <footer-navigation/>-->
      <div class="uk-container uk-padding-remove">
        <theFooter/>
      </div>
    </section>
  </div>
</template>
