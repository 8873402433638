<script setup>
import {ref, watch, onBeforeMount, computed} from 'vue';
import {_} from 'lodash';
import {router} from '../router/router.js';
import {useAuthStore} from '../stores/auth.store.js';
import {useAQStore} from '../stores/aq.store.js';
import {authHeader} from '../router/authheader.js';
import {useErrorCheckStore} from '../stores/errorCheck.store.js';
import Loading from '../Components/Loading.vue';
import AQObject from '../Components/AQObject.vue';
import Search from '../Components/Search.vue';

const baseUrl = process.env.APIURL;
const allowedLibraryExtensions = process.env.AllowedLibraryExtensions;
let currentAQ = ref();
let user = useAuthStore().getUser();
let objectSearch = ref();
let showUpdateMessage = ref(false);
let updateMessage = ref();
let isUpdateError = ref(false);
let newComment = ref();
// File Management
let Images = [];
let showImageMessage = ref(false);
let imageMessage = ref();
let isImageError = ref(false);
let qDeleteImages = ref([]);
let addedImages = ref();
const localKeys = ref(['Object ID', 'Unit #', 'UNIT #', 'Tag #', 'ASSET #', 'Asset #', 'Location', 'Status']);
const currentPO = ref(useAQStore().getCurrentPO);

onBeforeMount(async () => {
  // console.log("Getting table data");
  loading.value = true;
  user = useAuthStore().user;
  currentAQ = ref(useAQStore().currentAQ);
  await getObjectDetails();
  await fetchAQ(useAQStore().getCurrentPO);

  // set the default AQObject filter
  // currentLocationFilter.value = localStorage.getItem('currentLocation');
  // setFilter(currentLocationFilter.value, 'Location');
  // await fetchAQ(localStorage.getItem('currentPO'));
});

let currentObject = ref({});
const loading = ref(false);
const compDate = ref()

// AQ Object list variables 
const aqData = ref([]);
const tableHeaders = ref([]);
const aqObjects = ref([]);
const statusOptions = ref([]);
const locationOptions = ref([]);

const currentFilter = ref('all');
const filterKey = ref('Status');
const currentStatusFilter = ref('all');
const currentLocationFilter = ref('all');


const getObjectDetails = async () => {

  loading.value = true;
  let store = useAQStore();
  const user = useAuthStore().getUser();
  // if (!user.value) {
  //   return;
  // }
  const currentObjectID = store.getCurrentObjectID;
  // console.log("userId in AQ: ", user.uID);
  // console.log("currentObjectID in AQ: ", currentObjectID);
  const url = baseUrl + "/AQ" + "/GetObjectDetails";
  let returnValue = "";
  let call = {
    "UserID": user.uID,
    "ObjectID": currentObjectID
    // "ObjectID": 739204  <--- use this one for seeing how tables should look on the backend while debugging
  };
  const response = await fetch(url, {
    method: 'POST',
    headers: authHeader(url),
    body: JSON.stringify(call)
  })

  let checkResponse = useErrorCheckStore().checkStatus(response);
  if (response.status == 504) {
    useAuthStore().error504();
  }

  if (checkResponse) {
    returnValue = await response.json();
    let checkReturnValue = useErrorCheckStore().checkStatusJSON(returnValue);
    if (checkReturnValue) {
      // console.log("UI getByPO Response: ", returnValue)
      let data = returnValue.Data;
      currentLocationFilter.value = data.Location;
      setFilter(currentLocationFilter.value, 'Location');
      setCurrentObject(data);
      loading.value = false;

      localStorage.setItem("LocID", data.LocID);
      return data;
    }
  }
  loading.value = false;
}

const reFreshImages = async () => {
  console.log("reFreshImages");
  loading.value = false;
  let store = useAQStore();
  const user = useAuthStore().getUser();
  const currentObjectID = store.getCurrentObjectID;
  const url = baseUrl + "/AQ" + "/GetObjectDetails";
  let returnValue = "";
  let call = {
    "UserID": user.uID,
    "ObjectID": currentObjectID
    // "ObjectID": 739204  <--- use this one for seeing how tables should look on the backend while debugging
  };
  const response = await fetch(url, {
    method: 'POST',
    headers: authHeader(url),
    body: JSON.stringify(call)
  })

  let checkResponse = useErrorCheckStore().checkStatus(response);
  if (response.status == 504) {
    useAuthStore().error504();
  }

  if (checkResponse) {
    returnValue = await response.json();
    let checkReturnValue = useErrorCheckStore().checkStatusJSON(returnValue);
    if (checkReturnValue) {
      let data = returnValue.Data;
      setImageList(data);
      // loading.value = false;
    }
  }
  // loading.value = false;
}

const setImageList = (data) => {
  const keys = Object.keys(data);
  keys.forEach(key => {
    if (key === "ImageList") {
      console.log("setImageList ", data[key]);
      currentObject.value[key] = data[key];
    }
  })
}
const setCurrentObject = (data) => {
  const keys = Object.keys(data);
  // currentObject.value = data;
  keys.forEach(key => {
    currentObject.value[key] = data[key];
  })
  // currentObject.value.InstallCompDate = "2023-09-19";
  // console.log("Current object: ", currentObject.value);
}

const fetchSelectedObject = (selection) => {
  // console.log("Selected object: ", selection);
  useAQStore().setCurrentObjectID(parseInt(selection));
  // console.log("Current object ID: ", useAQStore().currentObjectID);
  getObjectDetails();
}

const fetchAQ = async (PO) => {
  console.log("fetchAQ", PO);
  const user = useAuthStore().user;
  const currentPO = PO;
  const url = baseUrl + "/AQ" + "/GetByPOUnsorted";
  let returnValue = "";
  let call = {
    "ID": user.uID,
    "PO": currentPO
  };
  const response = await fetch(url, {
    method: 'POST',
    headers: authHeader(url),
    body: JSON.stringify(call)
  });

  let checkResponse = useErrorCheckStore().checkStatus(response);
  if (checkResponse) {
    returnValue = await response.json();
    // console.log("getByPO Response: ", returnValue)
    let data = returnValue.Data;

    // map the data
    aqData.value = data.AQObject // aqData in the response
    // console.log("AQ Data: ", aqData.value);
    tableHeaders.value = data.Headers; // tableHeaders in the response
    aqObjects.value = data.Objects; // objects in the response
    statusOptions.value = data.StatusValues; // status options in the response
    locationOptions.value = data.LocationValues;
    // console.log("LocationOptions value: ", locationOptions.value);
    // return data;
  }
}

let _data = computed(() => {
  // let data = aqObjects.value;
  if (currentFilter.value === 'all') {

    if (objectSearch.value) {
      currentStatusFilter.value = 'all';
      currentLocationFilter.value = 'all';
      let newData = [];
      aqObjects.value.forEach((row) => {
        for (const [key, value] of Object.entries(row)) {
          // if (localKeys.value.includes(key)) {
          if (String(value).toLowerCase().includes(objectSearch.value.toLowerCase())) {
            if (!newData.includes(row)) {
              newData.push(row);
            }
          }
          // }
        }
      });
      // console.log("newData", newData);
      return newData;
    }

    let allData = [...aqObjects.value];
    return allData;
  }
  let filteredData = [...aqObjects.value];
  filteredData = filteredData.filter((item) => {
    if (item[filterKey.value] === currentFilter.value) {
      return true;
    }
    return false;
  });

  return filteredData;
})

const goBack = () => {
  // console.log("goBack");
  router.go(-1);
}

const AQDetails = () => {
  router.push({ name: 'aqDetails' });
}

const setFilter = (filter, category) => {
  objectSearch.value = '';
  // console.log("Setting filter: ", filter, " for category: ", category);
  currentFilter.value = filter;
  if (category == 'Status') {
    currentLocationFilter.value = 'all';
  }
  if (category == 'Location') {
    currentStatusFilter.value = 'Pending';
  }
  filterKey.value = category;
}

const formatKey = ((key) => {
  key = key.replace(/ /g, '-');
  key = key.replace(/\//g, '');
  key = key.replace(/&/g, 'and');
  return key;
})

const getInstallApprovalStatus = () => {
  if (!currentObject.value.ReqInstall) {
    return "N/A"
  }
  if (currentObject.value.IsInstallLowenApproved) {
    return "Installation Approved";
  } else {
    return "Pending";
  }
}

let installationStatus = computed(() => {
  let status = "N/A";
  if (currentObject.value.ReqInstall) {
    status = "Pending";
    if (currentObject.value.IsInstallLowenApproved) {
      status = "Completed, pending approval";
    }
    if (currentObject.value.IsInstallCustomerApproved) {
      status = "Approved, ready for invoice";
    }
    if (currentObject.value.InstallInvoiceSubmitted) {
      status = "Invoice submitted, awaiting approval";
    }
    if (currentObject.value.IsInstallInvoiced) {
      status = "Invoice approved";
    }

  }
  return status;
})

let removalStatus = computed(() => {
  let status = "N/A";
  if (currentObject.value.ReqRemoval) {
    status = "Pending";
    if (currentObject.value.IsRemLowenApproved) {
      status = "Completed, pending approval";
    }
    if (currentObject.value.IsRemCustomerApproved) {
      status = "Approved, ready for invoice";
    }
    if (currentObject.value.IsRemInvoiceSubmitted) {
      status = "Invoice submitted, awaiting approval";
    }
    if (currentObject.value.IsRemInvoiced) {
      status = "Invoice approved";
    }
  }
  return status;
})

const getRemovalApprovalStatus = () => {
  if (!currentObject.value.ReqRemoval) {
    return "N/A"
  }
  if (currentObject.value.IsRemLowenApproved) {
    return "Removal Approved";
  } else {
    return "Pending";
  }
}

const showSubmitInvoice = () => {
  let show = true;
  if (currentObject.value.ReqInstall) {
    // console.log("checkInstall", currentObject.value.IsInstallLowenApproved);
    if (!currentObject.value.IsInstallLowenApproved) {
      show = false;
    }
  }

  if (currentObject.value.ReqRemoval) {
    // console.log("checkRemoval", currentObject.value.IsRemLowenApproved)
    if (!currentObject.value.IsRemLowenApproved) {
      show = false;
    }
  }

  return show;
}

// Update Object
async function updateObject() {
  // console.log("currentObject", currentObject.value);
  showUpdateMessage.value = false;
  updateMessage.value = "";
  isUpdateError.value = false;
  const uObject = currentObject.value;
  let mapObject = {};
  mapObject.UserID = user.uID;
  mapObject.ObjectID = uObject.ObjectID;
  mapObject.Year = uObject.Year;
  mapObject.Make = uObject.Make;
  mapObject.Model = uObject.Model;
  mapObject.AppCompDate = formatBEDate(uObject.InstallCompDate);
  mapObject.RemCompDate = formatBEDate(uObject.RemovalCompDate);
  /// REMOVAL and INSTALL
  mapObject.NoRemoval = uObject.ReqRemoval
  mapObject.NoInstall = uObject.ReqInstall
  mapObject.Comment = null;

  let stringComment = newComment.value;
  if (!_.isUndefined(stringComment)) {
    mapObject.Comment = newComment.value;
  }
  // Deprecated. We add and remove images with separate calls now.
  mapObject.addImages = [];
  mapObject.removeImages = [];
  // Unknown what ObjectsList means here 
  mapObject.ttObjectsIDs = uObject.ObjectsIDs;

  // ttCharges. Up-charges and additional charges. Rarely used.
  // Logic added 2/2024
  mapObject.ttCharge = uObject.ChargesList;

  // console.log("UpdateObjectDetails mapObject", mapObject);
  let result = await UpdateObjectDetails(mapObject);
  if (result) {
    getObjectDetails();
    if (newComment.value.length > 0) {
      // console.log("clearing comment");
      newComment.value = "";
    }
  }
}

async function UpdateObjectDetails(oObject) {
  const url = baseUrl + "/AQ" + "/UpdateObjectDetails";
  // console.log("Update Object: ", oObject);
  const response = await fetch(url, {
    method: "POST",
    headers: authHeader(url),
    body: JSON.stringify(oObject)
  });

  let checkResponse = useErrorCheckStore().checkStatus(response);
  if (checkResponse) {
    const updateResult = await response.json();
    let checkResult = useErrorCheckStore().checkStatusJSON(updateResult, true);
    return checkResult;
  }
}

// Masv File Uploads
async function getToken() {
  // console.log("getToken");
  const { user } = useAuthStore();
  const userId = user.uID;
  const url = baseUrl + "/Library" + "/new_token";
  let response = await fetch(url,
      {
        method: "GET",
        headers: authHeader(url),
      }
  );
  let checkResponse = useErrorCheckStore().checkStatus(response);
  if (checkResponse) {
    let tokens = await response.json();
    // console.log("tokens", tokens);
    return {
      packageID: tokens.id,
      packageToken: tokens.access_token
    }
  }
}

function uploadInstallationImages(e) {
  // console.log("uploadInstallationImages");
  let files = e.target.files;
  _.forEach(files, function (f, k) {
    // console.log("file", f);
    Images[k] = f;
  });
  // console.log("Images", Images);
  uploadFile();
}

function uploadFile() {
  // console.log("uploadFile");
  const userId = user.uID;
  let _this = this;
  showImageMessage.value = true;
  isImageError.value = false;
  _.forEach(Images, function (f, k) {
    // log("file info to use in progress: f", f);
    imageMessage.value = "Uploading file... " + f.name;
    MASVUpload(f);
  });
}

async function MASVUpload(uploadFile) {
  // console.log("MASVUpload");
  const userId = user.uID;
  const url = baseUrl + "/Library" + "/handle_masv";
  const filesArray = [{
    file: uploadFile,
    path: "",
    id: 0
  }];
  // console.log("filesArray", filesArray);
  // console.log("masvURL", url);
  const MASVToken = await getToken();
  // console.log("masvtoken", MASVToken);
  const packageID = MASVToken.packageID;
  // const packageID = undefined;
  const packageToken = MASVToken.packageToken;
  let ready = true;
  if (packageID === null || packageID === undefined) {
    console.log("No MASV");
    ready = false;
    imageMessage.value = "Error! File uploading service is unavailable. Please try again in a few minutes.";
    isImageError.value = true;
  }
  if (ready) {
    const MASVuploader = new MASV.Uploader(
        packageID,
        packageToken,
        "https://api.massive.app"
    );
    let listener = {
      onComplete: async () => {
        try {
          console.log("MASV onComplete");
          // log("onComplete newFolder", newFolderName);
          // console.log("onComplete selectedFolder", selectedFolder);
          const formData = {
            "userId": userId,
            "packageid": packageID,
            "AQId": currentAQ.value,
            "ObjectId": currentObject.value.ObjectID,
            "SavingStrategy": "Installation",
            "PoId": currentPO.value
          };
          imageMessage.value = "Moving files into folder..."
          // console.log("MASVUpload onComplete url ", url);
          console.log("MASVUpload onComplete formData ", formData);
          const result = await fetch(url, {
            method: 'POST',
            headers: authHeader(url),
            body: JSON.stringify(formData)
          });
          let checkResult = useErrorCheckStore().checkStatus(result);
          if (checkResult) {
            const uploadData = await result.json();
            let checkUploadData = useErrorCheckStore().checkStatusJSON(uploadData, true, false);
            if (checkUploadData) {
              // console.log("MASV onComplete uploadData", uploadData);
              if (uploadData.Status === "ok") {
                imageMessage.value = uploadData.Message;
                // await getObjectDetails();
                console.log("call refreshimages");
                await reFreshImages();
              }
              if (uploadData.Status === "error") {
                imageMessage.value = "Error! " + uploadData.Message;
                isImageError.value = true;
              }
              if (uploadData.Status === "exception") {
                imageMessage.value = "Exception! " + uploadData.Message;
                isImageError.value = true;
              }
            }
          }
        } catch (e) {
          // console.log("MASV onComplete exception", e);
          // console.log("MASV onComplete exception upload data", uploadData);
          imageMessage.value = "There was an error uploading the file. " + e;
          isImageError.value = true;
        }
      },
      onError: e => {
        // console.log("listener on Error", e);
        imageMessage.value = "There was a process error uploading the file." + e;
        isImageError.value = true;
        showImageMessage.value = true;
      },
      onFileComplete: f => {
        // console.log("MASV listener onFileComplete", f.file.name);
        imageMessage.value = "Completed File... " + f.file.name;
        showImageMessage.value = true;
        isImageError.value = false;
      },
      onProgress: (transferred, total, speedStat) => {
        // console.log("MASV listener onProgress", speedStat);
        const progress = Math.floor(transferred / total * 100);
        imageMessage.value = "Upload progress: " + progress;
        showImageMessage.value = true;
        isImageError.value = false;
      },
      onRetry: e => {
        // console.log("MASV listener onRetry", e);
        imageMessage.value = "Error! Processing a retry...";
        showImageMessage.value = true;
        isImageError.value = true;
      }
    };
    // console.log("MASV here we go...");
    MASVuploader.setListener(listener);
    MASVuploader.upload(...filesArray);
  }
}

// Add images to a delete que based on check mark.
function deleteImagesQ(event) {
  // console.log("deleteTarget", event.target.name);
  const targetName = parseInt(event.target.name);
  const currentQ = qDeleteImages.value;
  // console.log("currentQ", currentQ);
  const f = currentQ.indexOf(targetName);
  // console.log("targetInQ", f);
  // Add or remove from deleteQ
  if (f < 0) {
    currentQ.push(targetName);
  } else {
    currentQ.splice(f, 1);
  }
  // Setting delete images Q to value of temp value currentQ
  qDeleteImages.value = currentQ;
}

// Run through the delete images que and call a InstallerRemovePhoto for each one.
async function removeImages() {
  isImageError.value = false;
  imageMessage.value = false;
  showImageMessage.value = false;
  let currentQ = qDeleteImages.value;
  let removeResult = false;
  currentQ.every(async (sequence) => {
    removeResult = await removePhoto(sequence);
    // console.log("removeResult", removeResult);
    if (!removeResult) {
      // reset the delete Q to empty.
      qDeleteImages.value = [];
      imageMessage.value = "Could not remove one or more of the images.";
      isImageError.value = true;
      showImageMessage.value = true;
    }
    // remove the element
    if (removeResult) {
      currentQ.pop();
    }
    // When array is 0. Refresh the page if no errors.
    // console.log("currentQ length", currentQ.length);
    if (currentQ.length === 0) {
      // console.log("currenQ is", currentQ);
      // console.log("qDeleteImages is", qDeleteImages.value);
      if (!isImageError.value) {
        // console.log("Success, should see the cog");
        imageMessage.value = "Success";
        showImageMessage.value = true;
        // reset the delete Q to empty.
        qDeleteImages.value = [];
        // await getObjectDetails();
        await reFreshImages();
      }
    }
    return removeResult;
  });
}

// The InstallerRemovePhoto call
async function removePhoto(imageSequence) {
  let resultValue = false;
  const userId = user.uID;
  const url = baseUrl + "/Library" + "/InstallerRemovePhoto";
  const objectId = currentObject.value.ObjectID;
  const rImage = {
    UserId: userId,
    ObjectId: objectId,
    Sequence: imageSequence,
  };
  // console.log("calling InstallerRemovePhoto", rImage);
  const result = await fetch(url, {
    method: 'POST',
    headers: authHeader(url),
    body: JSON.stringify(rImage)
  });
  let checkResult = useErrorCheckStore().checkStatus(result);
  if (checkResult) {
    let jsonResult = await result.json();
    resultValue = useErrorCheckStore().checkStatusJSON(jsonResult, true);
    return resultValue;
  }
}

function showObject(ob) {
  // console.log("showObject", ob);
  let returnValue = false;
  const req = ob.labelRequired;
  const val = ob.idValue;
  if (req != "False") {
    returnValue = true;
  }
  if (val.length > 0) {
    returnValue = true;
  }
  return returnValue;
}

function showGroupID() {
  let returnValue = "";
  const classValue = currentObject.value.Class;
  const typeValue = currentObject.value.Type;
  const locationID = currentObject.value.LocID;
  const lgl = currentObject.value.LocationGroupsList;
  _.forEach(lgl, function (el, k) {
    let c = el.groupClass;
    let t = el.groupType;
    let l = el.locID;
    if (l == locationID) {
      if (c == classValue) {
        if (t == typeValue) {
          returnValue = el.groupID;
        }
      }
    }
  });
  return returnValue;
}

watch(objectSearch, (newVal, oldVal) => {
  // console.log("Search changed to: ", newVal);
  if (oldVal == '') {
    currentFilter.value = 'all';
  }
  // console.log("objectSearch", objectSearch.value);
});

// Deep watch on currentObject. Works for changing Install and Removal
watch(currentObject, (newValue, oldValue) => {
  resetCompletionDate('ReqInstall', 'InstallCompDate');
  resetCompletionDate('ReqRemoval', 'RemovalCompDate');
}, { deep: true });

function resetCompletionDate(reqProp, dateProp) {
  if (!currentObject.value[reqProp]) {
    currentObject.value[dateProp] = '';
  }
}

// Helpers
function formatHTMLDate(dString) {
  // console.log("formatHTMLDate", typeof dString);
  // console.log("formatHTMLDate", dString);
  let d = "";
  if (!_.isUndefined(dString)) {
    d = dString.slice(0, 10);
  }
  // console.log("formatHTMLDate", d);
  return d
}

function formatBEDate(dString) {
  // console.log("dString", dString);
  let d = "";
  if (!_.isNil(dString)) {
    if (dString.length > 0) {
      // console.log("should not be here");
      const date = new Date(dString);
      // console.log("formatBEDate", date);

      // this way of converting the date causes the date to be off by one day
      // var year = date.toLocaleString("default", {year: "numeric"});
      // var month = date.toLocaleString("default", {month: "2-digit"});
      // var day = date.toLocaleString("default", {day: "2-digit"});

      // changing to this.  This seems to work
      var year = date.getUTCFullYear();
      var month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
      var day = date.getUTCDate().toString().padStart(2, "0");
      // console.log("day: ", day);
      d = month + "-" + day + "-" + year;
      // console.log("formated BE Date", d);
    }
  }
  return d
}

// FUTURE
/*function isVideo(path) {
  // console.log("isVideo", path);
  let returnValue = false;
  // if (path.includes("592568-16")) {
  //     console.log("isVideo true");
  //   returnValue = true;
  // }
  return returnValue;
}*/

</script>

<template>
  <div class="uk-container uk-margin-large-bottom loading-animation">
    <Loading v-if="loading"/>
    <div v-else>
      <div class="uk-margin-top">
        <a class="uk-text-secondary" href="#" v-on:click="goBack"><span class="uk-text-secondary"
                                                                        uk-icon="icon: chevron-double-left"></span> Back</a>
      </div>

      <div class="all-content">
        <div class="main-content-container uk-flex uk-flex-column">
          <section class="top uk-flex uk-flex-column uk-child-width-1-1">
            <div class="left-side uk-form uk-box-shadow-medium uk-padding uk-margin-top">
              <div v-if="loading" class="loading-animation">
                <Loading/>
              </div>
              <!-- Start Unit Information Section-->
              <div v-if="!loading" id="unit-information-section" class="unit-information-section">
                <h3 class="uk-text-center uk-margin-small-top uk-text-bold">UNIT INFORMATION @
                  <a v-on:click="AQDetails">{{ currentObject.Location }}</a>
                </h3>
                <div class="uk-flex uk-width-1-1 uk-margin-top">
                  <AgileButton uk-toggle="target: #ObjectListSection"><span uk-icon="icon: chevron-double-left"></span> Object List</AgileButton>
                </div>
                <hr><!------------------------------------------------------------->

                <div class="uk-form uk-flex uk-flex-column">
                  <div class="location-container uk-margin-bottom">
                    <!-- Location seems to only show if there is more than one.  I think selecting a group dictates the locID-->
                    <div class="input-group uk-flex uk-flex-column">
                      <label class="uk-text-bold" :for="'locationInput' + currentObject.oObjectID">Location</label>
                      <div class="uk-position-relative">
                        <input class="uk-input" type="text" :id="'locationInput'+ currentObject.oObjectID"
                               v-model="currentObject.Location" readonly/>
                        <span class="uk-position-center-right uk-margin-small-right" uk-icon="icon: lock"></span>
                      </div>
                    </div>
                  </div>
                  <div
                      class="vehicle-top-group uk-flex uk-margin-bottom uk-child-width-1-1 uk-child-width-1-3@s uk-grid-small"
                      uk-grid>
                    <div class="input-group uk-flex uk-flex-column uk-position-relative">
                      <label class="uk-text-bold" :for="'group' + currentObject.oObjectID">Group</label>
                      <div class="uk-position-relative">
                        <input class="uk-input" type="text" :id="'group' + currentObject.oObjectID"
                               v-bind:value="showGroupID()" readonly>
                        <span class="uk-position-center-right uk-margin-small-right" uk-icon="icon: lock"></span>
                      </div>
                    </div>
                    <div class="input-group uk-flex uk-flex-column" :class="{'uk-width-expand': !showGroupID()}">
                      <label class="uk-text-bold" :for="'classInput' + currentObject.oObjectID">Class</label>
                      <div class="uk-position-relative">
                        <input class="uk-input" type="text" :id="'classInput' + currentObject.oObjectID"
                               v-model="currentObject.Class" readonly/>
                        <span class="uk-position-center-right uk-margin-small-right" uk-icon="icon: lock"></span>
                      </div>
                    </div>
                    <div class="input-group uk-flex uk-flex-column">
                      <label class="uk-text-bold" :for="'typeInput' + currentObject.oObjectID">Type</label>
                      <div class="uk-position-relative">
                        <input class="uk-input" type="text" :id="'typeInput' + currentObject.oObjectID"
                               v-model="currentObject.Type" readonly/>
                        <span class="uk-position-center-right uk-margin-small-right" uk-icon="icon: lock"></span>
                      </div>
                    </div>
                    <div class="input-group uk-flex uk-flex-column">
                      <label class="uk-text-bold" :for="'yearInput' + currentObject.oObjectID">Year</label>
                      <div class="uk-position-relative">
                        <input class="uk-input" type="text" :id="'yearInput' + currentObject.oObjectID"
                               v-model="currentObject.Year"/>
                      </div>
                    </div>
                    <div class="input-group uk-flex uk-flex-column">
                      <label class="uk-text-bold" :for="'makeInput' + currentObject.oObjectID">Make</label>
                      <input class="uk-input" type="text" :id="'makeInput' + currentObject.oObjectID"
                             v-model="currentObject.Make"/>
                    </div>
                    <div class="input-group uk-flex uk-flex-column">
                      <label class="uk-text-bold" :for="'modelInput' + currentObject.oObjectID">Model</label>
                      <input class="uk-input" type="text" :id="'modelInput' + currentObject.oObjectID"
                             v-model="currentObject.Model"/>
                    </div>
                  </div>
                  <div
                      class="vehicle-middle-group uk-flex uk-margin-bottom uk-child-width-1-1 uk-child-width-1-2@s uk-grid-small uk-margin-remove-vertical"
                      uk-grid>
                    <div v-for="(ob, index) in currentObject.ObjectsIDs">
                      <div v-if="showObject(ob)" class="input-group uk-flex uk-flex-column">
                        <label class="uk-text-bold"
                               :for="'Obj_'+currentObject.oObjectID + '_serial_' + index + formatKey( ob.idValue)">{{
                            ob.labelValue
                          }}</label>
                        <input class="uk-input" type="text"
                               :id="'Obj_' + currentObject.oObjectID + '_serial_' + index + formatKey( ob.idValue)"
                               v-model="ob.idValue" :readonly="!ob.labelMutable"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end information section-->

              <!-- Services section-->
              <div id="unit-services-section" class="unit-services-section uk-margin-bottom">
                <h3 class="uk-text-center uk-text-bold">Unit Services</h3>
                <hr class=""><!------------------------------------------------------------->
                <div
                    class="uk-visible@s service-headers uk-flex uk-flex-between uk-text-center uk-margin-remove uk-padding-small uk-text-bold uk-padding-remove-horizontal">
                  <p class="uk-margin-remove">Service Needed</p>
                  <p class="uk-margin-remove">Completion Date</p>
                  <p class="uk-margin-remove">Approval Status</p>
                </div>
                <div id="InstallationStatus"
                     class="uk-flex uk-flex-between uk-child-width-1-1 uk-child-width-1-3@s uk-margin-small-bottom"
                     uk-grid>
                  <div class="input-group uk-flex uk-flex-bottom uk-margin-small-bottom">
                    <label class="uk-form-label" for="requiredInstallCheckbox">
                      <input class="uk-checkbox" type="checkbox" id="requiredInstallCheckbox"
                             v-model="currentObject.ReqInstall"/>
                      Installation</label>
                  </div>
                  <div class="input-group uk-width-1-1 uk-width-1-3@s">
                    <label class="uk-hidden@s" for="installationDate">Completion Date</label>
                    <input class="uk-input uk-width-1-1" type="date" id="installationDate"
                           v-model="currentObject.InstallCompDate"
                           :disabled="!currentObject.ReqInstall"
                           :readonly="installationStatus == 'Invoiced approved'"/>
                  </div>
                  <div>
                    <label class="uk-hidden@s" for="installApprovalStatus">Installation Approval Status</label>
                    <input class="uk-input readonly"
                           :class="{'uk-alert-success' : installationStatus == 'Invoice approved',
                                  'uk-alert-warning' : installationStatus != 'Invoice approved'}"
                           type="text" id="installApprovalStatus"
                           v-bind:value="installationStatus" :disabled="installationStatus == 'N/A'"
                           readonly/>
                  </div>
                </div>
                <div id="RemovalStatus"
                     class="uk-flex uk-flex-between uk-child-width-1-1 uk-child-width-1-3@s uk-margin-small-bottom"
                     uk-grid>
                  <div class="input-group uk-flex uk-flex-bottom uk-margin-small-bottom">
                    <label class="uk-form-label" for="requiredRemovalCheckbox">
                      <input class="uk-checkbox" type="checkbox" id="requiredRemovalCheckbox"
                             v-model="currentObject.ReqRemoval"/>
                      Removal</label>
                  </div>
                  <div class="input-group uk-width-1-1 uk-width-1-3@s">
                    <label class="uk-hidden@s" for="removalDate">Completion Date</label>
                    <input class="uk-input" type="date" id="removalDate"
                           v-model="currentObject.RemovalCompDate"
                           :disabled="!currentObject.ReqRemoval"
                           :readonly="removalStatus == 'Invoice approved'"/>
                  </div>
                  <div>
                    <label class="uk-hidden@s" for="removalApprovalStatus">Removal Approval Status</label>
                    <input class="uk-input"
                           :class="{'uk-alert-success' : removalStatus == 'Invoice approved',
                                    'uk-alert-warning' : removalStatus != 'Invoice approved'}"
                           type="text" id="removalApprovalStatus"
                           v-bind:value="removalStatus" readonly
                           :disabled="removalStatus == 'N/A'"/>
                  </div>
                </div>
                <div class="uk-margin-top uk-section uk-padding uk-padding-remove-top uk-section-secondary"
                     v-if="currentObject.ChargesList.length > 0">
                  <div>
                    <h4 class="uk-text-center uk-padding-small">Other Charges</h4>
                  </div>
                  <div id="OtherChargesStatus"
                       class="uk-child-width-1-1 uk-child-width-1-3@s"
                       uk-grid>
                    <div v-for="(cg, cgKey) in currentObject.ChargesList">
                      <div class="input-group">
                        <label class="uk-form-label" :for="cg.description">
                          <input class="uk-checkbox" type="checkbox" :id="cg.description"
                                 :checked="cg.selected"
                                 v-model="currentObject.ChargesList[cgKey].selected"/>
                          {{ cg.description }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- End Other Charges -->
                <div v-if="showSubmitInvoice()" class="uk-flex uk-width-1-1 uk-flex-right uk-margin-top">
                  <!--  If Ready to Invoice.  Add v-if="All services approved"-->
                  <div class="uk-position-relative uk-overflow-hidden uk-width-1-1 uk-width-auto@s">
                    <AgileButton v-on:click="router.push('invoice')">Quick Submit Invoice</AgileButton>
                  </div>
                </div>
              </div>
              <!-- end services section-->

              <!-- Start Comments section-->
              <div id="unit-comments-section" class="unit-comments-section">
                <div class="comments-image-container">
                  <div class="comments-container uk-flex uk-flex-column uk-flex-between">
                    <div v-if="currentObject.CommentsList && currentObject.CommentsList.length > 0"
                         class="uk-margin-top">
                      <div v-for="comment in currentObject.CommentsList"
                           class="comment uk-flex uk-flex-column uk-margin-bottom">
                        <div class="comment-header uk-flex uk-flex-between uk-background-primary">
                          <div class="comment-header-left uk-flex uk-padding-small uk-padding-remove-vertical"
                               style="color: white;">
                            <span class="uk-margin-right">{{ comment.user }}</span>
                            <span class="">{{ comment.date.substring(0, 10) }}</span>
                          </div>
                        </div>
                        <div class="comment-body uk-flex uk-flex-column">
                          <p class="uk-margin-left">{{ comment.comment }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="add-comment-input input-group uk-flex uk-flex-column uk-padding-right">
                      <label class="uk-text-bold" for="comments">Add Comment</label>
                      <textarea v-model="newComment" id="comments" class="uk-textarea"></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End comments section-->
              <div class="uk-width-1-1 uk-position-relative uk-margin-top">
                <div class="uk-child-width-1-2" uk-grid>
                  <div>
                    <AgileButton class="uk-float-left" uk-toggle="target: #ObjectListSection"><span uk-icon="icon: chevron-double-left"></span> Object List</AgileButton>
                  </div>
                  <div>
                    <AgileButton class="uk-float-right" v-on:click="updateObject">Save Changes</AgileButton>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="bottom">
            <!-- Start Images section-->
            <div id="unit-images-section" class="unit-images-section">
              <div
                  class="image-section-container uk-form uk-box-shadow-medium uk-padding uk-margin-top ">
                <div class="uk-position-relative">
                  <h3 class="uk-text-center uk-text-bold uk-margin-remove-top">Unit Images</h3>
                  <div>
                    <div v-if="qDeleteImages.length == 0" class="js-upload uk-placeholder image-upload uk-width-1-1"
                         uk-form-custom>
                      <div class="drag-drop-panel uk-panel uk-height-small uk-box-shadow-medium">
                        <div class="uk-text-center">
                          <div uk-icon="icon: cloud-upload; ratio: 2"></div>
                          <div class="uk-text-lead">Drag-N-Drop Images here, or&nbsp;&nbsp;...&nbsp;&nbsp;<AgileButton
                              v-on:click="uploadInstallationImages">Upload Images
                          </AgileButton>
                          </div>
                        </div>
                      </div>
                      <input class="file-upload uk-input" type="file" multiple :onchange="uploadInstallationImages"
                             :accept="allowedLibraryExtensions">
                    </div>
                    <AgileButton v-if="qDeleteImages.length > 0" type="button"
                                 class="uk-button uk-button-warning project-button-small" v-on:click="removeImages">
                      Remove Selected Images
                    </AgileButton>
                  </div>
                </div>
                <hr class=""><!------------------------------------------------------------->
                <div class="image-section-content-container">
                  <p><span class="uk-text-bold uk-text-danger">NOTE: </span>
                    <span v-if="qDeleteImages.length == 0">Upload Installation Images or PDFs.</span>
                    <span class="uk-text-bold" v-if="qDeleteImages.length > 0">Selecting images for removal.</span>
                  </p>
                  <div class="uk-alert"
                       :class="{'uk-hidden': !showImageMessage, 'uk-alert-primary': !isImageError, 'uk-alert-warning': isImageError}"
                       uk-alert>
                    {{ imageMessage }}
                  </div>
                  <div uk-lightbox="animation: slide;">
                    <div
                        class="image-container uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-1-4@m uk-child-width-1-5@l uk-child-width-1-6@xl uk-grid-small"
                        uk-grid>
                      <div v-for="im in currentObject.ImageList">
                        <!-- <div v-if="isVideo(im.FullPath)" class="uk-height-small uk-margin-bottom">-->
                        <!-- <video class="uk-background-cover uk-height-small uk-width-auto" controls>-->
                        <!--  <source class="uk-background-cover uk-height-small" :src="im.Base64String" type="video/mp4">-->
                        <!--  </video>-->
                        <!-- </div>-->
                        <a v-if="im.FileExists" v-bind:href="im.Base64String" data-caption="Uploaded Image File"
                           data-alt="Uploaded Image File" data-type="image">
                          <div v-bind:data-src="im.Base64String"
                               class="uk-height-small uk-background-cover uk-margin-bottom"
                               uk-img></div>
                        </a>
                        <a v-else href="#"
                           data-caption="Image is uploading or missing. Reload Unit Information to confirm."
                           data-alt="Image is uploading or missing. Reload AQ to confirm." data-type="text">
                          <h4 class="uk-height-small uk-flex uk-flex-middle">Processing Image...</h4>
                        </a>

                        <div class="uk-form-controls">
                          <label><input type="checkbox" class="uk-checkbox" :name="im.Sequence"
                                        :onchange="deleteImagesQ">&nbsp;Delete</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End images section-->
          </section>
        </div>
        <!--        End Left Side-->
        <div id="ObjectListSection" class="" uk-offcanvas>
          <div class="uk-offcanvas-bar uk-background-muted">
            <AgileCloseOffcanvas/>
            <h3 class="uk-text-bold uk-text-primary uk-text-center uk-margin-remove-bottom">PO# {{ currentPO }}</h3>
            <h4 class="uk-text-bold uk-text-primary uk-text-center uk-margin-remove-top">Object List</h4>
            <div class="uk-light uk-padding-small">
              <p class="uk-text-lead uk-margin-remove">Status Filters</p>
              <div class="uk-link">
                <select class="uk-select uk-width-expand height-40" name="locationSelection" :id="'LocationSelection1'"
                        v-model="currentStatusFilter" @change="setFilter(currentStatusFilter, 'Status')">
                  <option value="all" class="uk-background-muted uk-text-secondary">SHOW ALL</option>
                  <option v-for="option in statusOptions" class="uk-background-muted uk-text-secondary" :value="option">
                    {{ option }}
                  </option>
                </select>
              </div>
            </div>
            <div class="uk-light uk-padding-small uk-padding-remove-top">
              <p class="uk-text-lead uk-margin-remove">Location Filters</p>
              <div class="uk-link">
                <select class="uk-select uk-width-expand height-40" name="locationSelection" id="LocationSelection2"
                        v-model="currentLocationFilter" @change="setFilter(currentLocationFilter, 'Location')">
                  <option value="all" class="uk-background-muted uk-text-secondary">SHOW ALL</option>
                  <option v-for="location in locationOptions" class="uk-background-muted uk-text-secondary"
                          :value="location">{{ location }}
                  </option>
                </select>
              </div>
            </div>
            <div class="uk-padding-small uk-padding-remove-top uk-margin-small-bottom">
              <Search :ID="'Unit-Info-Search'" class=" uk-margin-small-top"
                      :Shadow="false"
                      v-model="objectSearch"/>
            </div>
            <div class="uk-position-relative">
              <ul class="uk-flex uk-flex-column uk-padding-remove uk-list">
                <li v-for="item in _data"
                    class="objects uk-margin-small-bottom uk-text-primary uk-padding-small uk-padding-remove-vertical">
                  <AQObject class="uk-light" v-on:click="fetchSelectedObject(item.ID)" :data="item" :keys="localKeys"
                            :filter="objectSearch"/>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
      <div class="uk-margin-top">
        <a class="uk-text-secondary" href="#" v-on:click="goBack"><span class="uk-text-secondary"
                                                                        uk-icon="icon: chevron-double-left"></span> Back</a>
      </div>
    </div>
  </div>
</template>