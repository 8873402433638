<script setup>
import { computed, onBeforeMount, onMounted, ref } from 'vue';
import {useAuthStore} from '../stores/auth.store.js';
import { useErrorCheckStore } from '../stores/errorCheck.store.js';
import { router } from '../router/router.js';
import Login from '../Components/Login'

let isAuthenticated = ref(false);
let user = computed(() => useAuthStore().getUser());
onMounted(() => {
  // console.log("Home page before mount");
  // if (useAuthStore().user) {
  //   console.log("We already have a user");
  //   // router.push('/projects');
  // }
  // let store = useAuthStore();
  // if (user.value) {
  //   console.log("User: ", user.value);
  //   isAuthenticated.value = true;
  //   router.push({name: 'projects'});
  // } else {
  //   isAuthenticated.value = false;
  // }
  // check to see if user was forced logged out
  if (localStorage.getItem('forceLogoutError')){
    useErrorCheckStore().pushError(localStorage.getItem('forceLogoutError'));
    // localStorage.removeItem('forceLogoutError');
  }
  // localStorage.removeItem('forceLogoutError');
})
//Computed
const allowedAgents = computed(() => {
  let returnValue = false;
  const authStore = useAuthStore();
  let allowed = process.env.AgentPagesAccess;
  if (authStore.user) {
    if (allowed.includes(authStore.user.Username)) {
      returnValue = true;
    }
  }
  return returnValue;
});
</script>

<template>
  <div class="uk-container">
    <section class="uk-section">
      <div class="uk-flex uk-flex-center">
        <Login v-if="!isAuthenticated"/>
      </div>
    </section>
  </div>
  <!-- End Container -->
</template>