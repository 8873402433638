<script setup>
import {onBeforeMount, onMounted, watch} from 'vue';
import {ref} from 'vue';
import {router} from '../router/router';
import {useRouter, useRoute} from 'vue-router';
import {useAuthStore} from '../stores/auth.store.js';
import {useAQStore} from '../stores/aq.store.js';
import {authHeader} from '../router/authheader';
import {useErrorCheckStore} from '../stores/errorCheck.store';
import UIkit from 'uikit';
import Icons from "uikit/dist/js/uikit-icons";
import Loading from '../Components/Loading.vue';

//Data
let appUser = ref({
  username: "",
  password: "",
  slipstring: ""
});

const vueRouter = useRouter();
const route = useRoute();
let loginMessage = ref("");
// let showError = ref(false);
const baseUrl = process.env.APIURL + "/Users";
let isAuthenticated = ref(false);
let store = useAuthStore();
let user = store.getUser();
let resetEmail = ref();
let resetMessage = ref("");
let resetStatus = ref();
let invalidLogin = ref(false);
let slipString = false;

let loading = ref(false);
//Lifecycle
onBeforeMount(async () => {
  UIkit.use(Icons);
  store = useAuthStore();
  if (route.query.email !== undefined) {
    // console.log("onBeforeMount, Slipstring");
    await clearLocalStorage();
    slipString = true;
    appUser.value.username = route.query.email;
    appUser.value.slipstring = route.query.slipstring;
    await webLogin();
  } else {
    // console.log("onBeforeMount, RegUser");
    if (user) {
      isAuthenticated.value = true;
    } else {
      isAuthenticated.value = false;
    }
  }

});

//Methods
const appLogin = async (call) => {
  loading.value = true;
  console.log("appLogin");
  // console.log("call: ", call);
  let returnValue = "";
  const url = baseUrl + "/authenticate";
  const response = await fetch(url, {
    method: 'POST',
    headers: authHeader(url),
    body: JSON.stringify(call)
  })

  let checkResponse = useErrorCheckStore().checkStatus(response);
  if (checkResponse) {
    returnValue = await response.json();
    let checkData = useErrorCheckStore().checkStatusJSON(returnValue, false, true);
    if (!checkData) {
      invalidLogin.value = true;
      returnValue = false;
    }
    return returnValue;
  }
  loading.value = false;
  returnValue = false;
  return returnValue;
}

const clearLocalStorage = async () => {
  console.log("clearLocalStorage");
  // if(localStorage.getItem("InstallationUser") != null){
  localStorage.removeItem("InstallationUser");
  // }
  // if(localStorage.getItem("InstallationJwtToken") != null){
  localStorage.removeItem("InstallationJwtToken");
  // }
  // if(localStorage.getItem("AQObject") != null){
  localStorage.removeItem("AQObject");
  // }
  // if(localStorage.getItem("currentPO") != null){
  localStorage.removeItem("currentPO");
  // }
  // if(localStorage.getItem("currentAQ") != null){
  localStorage.removeItem("currentAQ");
  // }
  // if(localStorage.getItem("currentProject") != null){
  localStorage.removeItem("currentProject");
  // }
  // if(localStorage.getItem("currentObjectID") != null){
  localStorage.removeItem("currentObjectID");
  // }
  // if(localStorage.getItem("LocID") != null){
  localStorage.removeItem("LocID");
  // }
  let a = useAuthStore();
  let b = useAQStore();
  a.clearStore();
  b.clearStore();
  // console.log("clearLocalStorage finito");
}

const webLogin = async () => {
  invalidLogin.value = false;
  if (useErrorCheckStore().errorList.length > 0) {
    useErrorCheckStore().clearErrorList();
  }
  // console.log("webLogin", appUser.value.username);
  // let ready = false;
  const authStore = useAuthStore();
  let ready = true;
  if (!slipString) {
    ready = useErrorCheckStore().validateSignIn(appUser.value.username, appUser.value.password);
  }
  
  if (ready) {
    // appUser.value.slipstring = "metoo";
    // console.log("ready: ", appUser.value);
    const call = {
      "Username": appUser.value.username,
      "Password": appUser.value.password,
      "Slipstring": appUser.value.slipstring,
      "franchise": "Installation"
    };
    // console.log("call: ", call);
    const response = await appLogin(call);
    if (response != false) {
      // console.log("weblogin response ", response);
      let rData = response.Data;
      const JwtToken = rData.JwtToken;
      // console.log("weblogin JwtToken", JwtToken);
      authStore.setJwtToken(JwtToken);
      delete rData.JwtToken;
      const user = rData;
      authStore.setUser(user);
      isAuthenticated.value = true;
      // showError.value = false;
      appUser.value.username = "";
      appUser.value.password = "";
      // console.log("appLogin user", user);
      // router.go("projects");
      console.log("Pushing to projects page");
      router.push({name: 'projects'});
      // let r = useRouter();
      // r.go('/');
      loading.value = false;
      if (response.Status === "ok") {
        console.log("Status is ok");
        router.push({name: 'projects'});
      }
      else if (response.Status === "forcepasswordchange") {
        // don't toggle, hide and show is better.
        UIkit.modal("#ChangePassModal").show();
      } else {
        if (response.Status === "exception") {
          // console.log("web login exception result", response);
          loginMessage.value = response.Message;
          // showError.value = true;
        }
      }
    }
    loading.value = false;
  } else {
    invalidLogin.value = true;
    loading.value = false;
  }
}

const resetPassword = async () => {
  // console.log("resetPassword");
  // console.log("resetEmail: ", resetEmail.value);
  // console.log("Base URL: ", baseUrl);
  let validateEmail = useErrorCheckStore().validateEmailPasswordReset(resetEmail.value);
  if (!validateEmail) {
    return;
  }
  const url = baseUrl + "/changePassword";
  // Need to send: UserID, AQNumber, LocationID
  let call = {
    "UserEmail": resetEmail.value
  };
  const response = await fetch(url, {
    method: 'POST',
    headers: authHeader(url),
    body: JSON.stringify(call)
  });

  let checkResponse = useErrorCheckStore().checkStatus(response);

  if (checkResponse) {
    const responseData = await response.json();
    // data.value = temp.Data;
    let checkData = useErrorCheckStore().checkStatusJSON(responseData);
    if (checkData) {
      // console.log("Data from resetPassword: ", responseData);
      resetStatus.value = responseData.Status;
      if (resetStatus.value === "ok") {
        resetEmail.value = "";
      }
    }
  }
}

const clearFields = () => {
  // log("Login clearFields");
  // showError.value = false;
  invalidLogin.value = false;
  appUser.value.username = "";
  appUser.value.password = "";
}

const clearInvalid = () => {
  if (invalidLogin.value) {
    invalidLogin.value = false;
  }
}
</script>

<template id="LoginTemplate">
  <div
      class="uk-width-large uk-padding uk-margin-auto-vertical">
    <h2 class="uk-modal-title uk-text-center">INSTALLER LOGIN</h2>
    <hr>
    <!------------------------------------------------------------------------------------------------------------------------------------>
    <!-- <div  v-if="showError" class="uk-alert uk-alert-warning">
      {{ loginMessage }}
    </div> -->
    <form autocomplete="on" class="uk-align-center uk-child-width-1-1 uk-position-relative">
      <Loading v-if="loading" :ratio="4" />
      <div class="uk-margin">
        <input id="txtUsername" class="uk-input uk-width-stretch" :class="{'error-border' : invalidLogin }" type="text"
               autocomplete="on"
               v-model="appUser.username" v-on:change="clearInvalid" placeholder="Email Address">
      </div>
      <div class="uk-margin">
        <input id="txtPassword" class="uk-input uk-width-stretch" :class="{'error-border' : invalidLogin}"
               type="password" autocomplete="on"
               v-model="appUser.password"
               v-on:change="clearInvalid"
               placeholder="Password">
      </div>
      <div class="uk-margin uk-flex uk-flex-center">
        <div class="uk-flex uk-flex-between uk-width-1-1 uk-child-width-1-2">
          <div class="uk-margin-small-right uk-position-relative">
            <AgileButton class="uk-width-expand" @click.prevent.default="webLogin">
              <span>Login</span>
            </AgileButton>
          </div>
          <div class="">
            <AgileButton class="uk-width-expand" @click.prevent.default="clearFields">Clear</AgileButton>
          </div>
        </div>
        <!-- <button class="uk-button uk-button-primary project-button-small uk-width-1-1" v-on:click.prevent="webLogin">
          Login
        </button>
      </div>
      <div class="uk-position-relative uk-overflow-hidden">
        <button class="uk-button uk-button-primary project-button-small uk-modal-close uk-width-1-1" v-on:click="clearFields">Clear
        </button>
      </div> -->
        <!-- <AgileButton @click="webLogin">Login</AgileButton>
        <AgileButton @click="clearFields">Clear</AgileButton> -->
      </div>
    </form>
    <div class="uk-text-small uk-width-1-1 uk-text-right">
      <a class="uk-text-right" href="#modal-center" uk-toggle>Forgot Password?</a>
    </div>

    <!-- Forgot Password Modal -->
    <div id="modal-center" class="modal-center uk-flex-top z-top " uk-modal>
      <div
          class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-padding uk-container uk-width-auto modal-body">
        <div class="">
          <button class="uk-modal-close-default" type="button" uk-close></button>
        </div>
        <div class="uk-position-relative">
          <h2 class="uk-text-bold uk-text-center uk-margin-remove">Password Reset</h2>
          <hr class="">
        </div>
        <div v-if="resetStatus == 'ok'"
             class="note-box-success uk-margin-top uk-margin-bottom uk-flex uk-flex-between uk-flex-middle uk-position-relative">
          <span class="uk-padding-small uk-width-stretch">{{ resetMessage }}</span>
        </div>
        <div v-if="resetStatus && resetStatus != 'ok'"
             class="note-box uk-margin-top uk-margin-bottom uk-flex uk-flex-between uk-flex-middle uk-position-relative">
          <span class="uk-padding-small uk-width-stretch">{{ resetMessage }}</span>
        </div>


        <div class="input-container ">
          <div class="uk-width-large uk-margin-bottom">
            <AgileInput v-if="resetStatus != 'ok'" v-model="resetEmail" :ID="'PasswordResetEmail'" :lable="'Email'"
                        :type="'text'" :readonly="false"/>
          </div>
          <div class="uk-overflow-hidden uk-position-relative">
            <button v-if="resetStatus != 'ok'" @click="resetPassword"
                    class="uk-button uk-button-primary project-button-small uk-width-1-1">Reset Password
            </button>
            <button v-if="resetMessage && resetStatus == 'ok'"
                    class="uk-button uk-button-primary project-button-small uk-width-1-1"
                    uk-toggle="target: #modal-center;">Close
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>