<!--
    When creating a LowenTable, you must pass data variable to the component.
    To use search component with LowenTable, you must pass v-model="refVariable" as a prop.
    All other props are optional.  Example below:

    Ex 1. (just data)
    <LowenTable :tableDate="dataRefVariable" />

    Ex 2. (data and search)
    <LowenTable v-model="searchRefVariable" :tableDate="dataRefVariable" />
-->

<script setup>

import {ref, computed, onBeforeMount} from 'vue';

// available props
const props = defineProps({
    ['modelValue']: {
        type: String,
        required: false,
    },
    searchText: {
        type: String,
        required: false,
    },
    tableHeaders: {
        type: Array,
        required: false,
    },
    tableData: {
        type: Array,
        required: true,
    },
    rowsPerPage: {
        type: Number,
        default: 5
    },
    showFooter: {
        type: Boolean,
        default: true
    },
    firstColumnController: {
        // This format allows for passing multiple controls to first column (ex: Crud buttons)
        type: Array,
        value: [{
            type: Object,
            value: {
                elType: {
                    type: String,
                    default: "button",
                },
                elText: {
                    type: String,
                    required: false,
                },
                icon: {
                    type: String,
                    required: false,
                },
                function: {
                    type: Function,
                }
            }
        }],
        required: false,
    },
    disablePagination: {
        type: Boolean,
        default: false
    },
    include: {
        type: Array,
        required: false,
    },
    exclude: {
        type: Array,
        required: false,
    },
    addControllers: {
        // This format allows for passing controls to 0 or more specified table columns
        type: Array,
        value: [{
            type: Object,
            value: {
                column: {
                    type: Number,
                    required: true,
                },
                elType: {
                    type: String,
                    required: false,
                },
                elText: {
                    type: String,
                    required: false,
                },
                function: {
                    type: Function,
                    required: true,
                }
            }
        }],
        required: false,
    }
})

onBeforeMount(() => {
  // if (props.addController){
  //     console.log("Add Controller: ", props.addController);
  // }
  // if (props.addControllers){
  //     console.log("Add Controllers: ", props.addControllers[0].function);
  // }
})
let tableHeaders = ref(props.tableHeaders)
let tableData = ref(props.tableData)
let showFooter = ref(props.showFooter)

// set rows per page based on if pagination is disabled or not
let getRowsPerPage = (() => {
  if (props.disablePagination) {
    return tableData.value.length;
  }
  return props.rowsPerPage;
})

// pagination variables
let rowsPerPage = getRowsPerPage();
let currentPage = ref(1);
let startItemNum = ref(0);
let endItemNum = ref(rowsPerPage);
let sorting = ref(false);
let sortingColumn = ref();
let sortedData = ref();
let totalPages = computed(() => {
  return Math.ceil(tableData.value.length / rowsPerPage);
});

let doPaginate = ref(!props.disablePagination);

let data = computed(() => {
    let start = 0;
    startItemNum.value = start + 1;
    let end = 0;

  // if search has a value
  if (props.modelValue) {
    // console.log("At paginateDate, modelValue: ", props.modelValue);
    doPaginate = false;
    let newData = [];
    tableData.value.forEach((row) => {
      for (const [key, value] of Object.entries(row)) {
        if (value.toLocaleLowerCase().includes(props.modelValue.toLocaleLowerCase())) {
          if (!newData.includes(row)) {
            newData.push(row);
          }
        }
      }
    });
    startItemNum.value = start + 1;
    endItemNum.value = newData.length;
    return newData;
  }

  // if there is no search value
  doPaginate = true;
  start = (currentPage.value - 1) * rowsPerPage;
  startItemNum.value = start + 1;
  end = currentPage.value * rowsPerPage;
  // console.log("Trying to splice: ", tableData.value);

  if (sorting === true) {
    let newTable = sortedData.value.slice(start, end);
    sorting = false;
    return newTable;
  }
  let newTable = tableData.value.slice(start, end);
  endItemNum.value = start + newTable.length;


  return newTable;
});

let changePage = (page) => {
  currentPage.value = page;
}

let getColSpan = computed(() => {
    let dataKeys = Object.keys(props.tableData[0]);
    let colSpan = dataKeys.length;
    if (props.firstColumnController) {
        colSpan += 1;
    }
    return colSpan;
})

// filter headers if include prop is passed
let filterHeaders = () => {
    let filteredHeaders = [];
    let headers = props.tableHeaders;
    let dataKeys = {}
    
    if (!headers){
        headers = [];
        dataKeys = Object.keys(props.tableData[0]);
        dataKeys.forEach(key => {
            headers.push(key);
        });
    }
    if (props.include) {
        headers.forEach(header => {
            if (props.include.includes(header)) {
                // console.log('Adding header: ', header);
                filteredHeaders.push(header);
            }
        });
        return filteredHeaders;
    }
    if (props.exclude) {
        headers.forEach(header => {
            if (!props.exclude.includes(header)) {
                // console.log('Adding header: ', header);
                filteredHeaders.push(header);
            }
        });
        return filteredHeaders;
    } 

    return headers;
}

// filter keys if include prop is passed
let filterKeys = () => {
  let filteredKeys = [];
  let dataKeys = Object.keys(props.tableData[0]);

  if (props.include) {
    dataKeys.forEach(key => {
      if (props.include.includes(key)) {
        // console.log('Adding key: ', key);
        filteredKeys.push(key);
      }
    });
    return filteredKeys;
  }
  if (props.exclude) {
    dataKeys.forEach(key => {
      if (!props.exclude.includes(key)) {
        // console.log('Adding key: ', key);
        filteredKeys.push(key);
      }
    });
    return filteredKeys;
  }
  filteredKeys = dataKeys;

  return filteredKeys;
}

// this is how to use a function as a prop (index needed to handle multiple conrollers. Omit if single item passed in)
let select = (item, index) => {
  if (props.firstColumnController[index].function) {
    return props.firstColumnController[index].function(item);
  }

  // console.log("No function passed to LowenTable");
}

let controllerFunction = (item, index) => {
  let functionFound = false;
  props.addControllers.forEach((controller) => {
    if (controller.column === index) {
      functionFound = true;
      return controller.function(item);
    }
  });

  if (functionFound === false) {
    // console.log("No function passed to LowenTable");
  }
}

let convertColumnIndex = (index) => {
  for (let i = 0; i < props.addControllers.length; i++) {
    if (props.addControllers[i].column === index) {
      return i;
    }
  }
}

let checkForIndexMatch = (index) => {
  for (let i = 0; i < props.addControllers.length; i++) {
    if (props.addControllers[i].column === index) {
      return true;
    }
  }
  return false;
}

let sortAscending = (header) => {
  // console.log("Sorting ascending at header: ", header);
  let dataSorted = tableData.value.sort((a, b) => {
    if (a[header].toLocaleLowerCase() < b[header].toLocaleLowerCase()) {
      return -1;
    }
    if (a[header].toLocaleLowerCase() > b[header].toLocaleLowerCase()) {
      return 1;
    }
    return 0;
  });
  // console.log("Returning sorted data ascending: ", dataSorted);
  sortedData.value = dataSorted;
}

let sortDescending = (header) => {
  // console.log("Sorting descending at header: ", header);
  let dataSorted = tableData.value.sort((a, b) => {
    if (a[header].toLocaleLowerCase() > b[header].toLocaleLowerCase()) {
      return -1;
    }
    if (a[header].toLocaleLowerCase() < b[header].toLocaleLowerCase()) {
      return 1;
    }
    return 0;
  });
  // console.log("Returning sorted data descending: ", dataSorted);
  sortedData.value = dataSorted;
}

let startSort = (sortMethod, index) => {
  // console.log("Sorting at column: ", index);
  sorting = true;
  sortingColumn.value = index;
  if (sortMethod === 'ascending') {
    sortAscending(index);
  }
  if (sortMethod === 'descending') {
    sortDescending(index);
  }
}
</script>


<template>
  <div id="groot">
    <table
        class="table uk-table uk-table-striped uk-table-hover uk-table-justify uk-table-responsive uk-box-shadow-medium uk-margin-top">
      <thead>
      <tr>
        <th v-if="firstColumnController" class="uk-padding-remove uk-background-primary uk-light"></th>
        <th class="uk-padding-remove uk-background-primary uk-light" v-for="header, index in filterHeaders()">
          <div class="table-el-container uk-flex uk-flex-middle uk-flex-center uk-margin-left uk-margin-right">
            <span class="uk-margin-right uk-light">{{ header }}</span>
            <div class="sort-btn-container uk-flex uk-flex-column">
              <a class="sort-ascending" @click="startSort('ascending', header)"><span
                  uk-icon="icon: triangle-up"></span></a>
              <a class="sort-descending" @click="startSort('descending', header)"><span
                  uk-icon="icon: triangle-down"></span></a>
            </div>
          </div>
        </th>
      </tr>
      </thead>
      <tbody class="table-body">
      <tr v-for="item in data" :key="item.ID">
        <td v-if="props.firstColumnController" class="uk-flex uk-width-auto uk-flex-center">
          <div v-for="controller, index in props.firstColumnController" class="uk-flex uk-flex-middle uk-flex-center">
            <button v-if="controller.elType === 'button'"
                    class="view-button uk-button-secondary uk-padding-small uk-margin-small-left"
                    @click="select(item, index)">
              <span v-if="controller.icon" :uk-icon="'icon:' + controller.icon"></span>
              <span v-else>{{ controller.elText }}</span>
            </button>
            <a v-else href="#"
               class="uk-margin-small-left"
               @click="select(item, index)">
              {{ controller.elText }}
            </a>
          </div>
        </td>
        <td v-for="param, index in filterKeys()"
            :class="{'search-result' : modelValue && item[param].toLocaleLowerCase().includes(modelValue.toLocaleLowerCase()), 'uk-text-middle' : param}">
          <div v-if="props.addControllers">
            <div v-if="checkForIndexMatch(index)">
              <button v-if="props.addControllers[convertColumnIndex(index)].elType === 'button'"
                      class="view-button uk-button uk-button-default uk-padding-remove uk-margin-small-left"
                      @click="controllerFunction(item, index)">
                <div v-if="props.addControllers[convertColumnIndex(index)].icon">
                  <span :uk-icon="'icon:' + props.addControllers[convertColumnIndex(index)].icon"></span>
                </div>
                <span v-if="props.addControllers[convertColumnIndex(index)].elText">{{
                    props.addControllers[convertColumnIndex(index)].elText
                  }}</span>
                <span class="uk-padding-small" v-else>{{ item[param] }}</span>
              </button>
              <a v-else href="#"
                 class="uk-margin-small-left"
                 @click="controllerFunction(item, index)">
                {{ item[param] }}
              </a>
            </div>
            <p v-else class="uk-align-middle uk-margin-remove">{{ item[param] }}</p>
          </div>
          <p v-else class="uk-padding-left uk-align-center uk-margin-left"><span class="uk-margin-remove uk-margin-left">{{
              item[param]
            }}</span></p>
        </td>
      </tr>
      </tbody>
      <tfoot v-if="showFooter">
      <tr>
        <td class="pagination-info uk-background-primary uk-light" :colspan="getColSpan">
          <div class="uk-flex uk-flex-between uk-flex-middle">
            <div class="uk-margin-left">
              <span class="uk-margin-small-right">Showing {{ startItemNum }} - {{
                  endItemNum
                }}</span><span> ( of {{ tableData.length }} )</span>
            </div>
            <div class="uk-margin-right">
              <ul v-if="tableData.length > rowsPerPage && doPaginate" class="uk-pagination uk-margin-remove">
                <li v-if="currentPage > 1"><a href="#" @click.stop.prevent="changePage(currentPage - 1)"><span
                    uk-pagination-previous></span></a></li>
                <li v-for="page in totalPages" :key="page"
                    :class="{'active' : page === currentPage, 'not-active' : page != currentPage}">
                  <a href="#" @click.stop.prevent="changePage(page)">{{ page }}</a>
                </li>
                <li><a href="#" @click.stop.prevent="changePage(currentPage + 1)"><span uk-pagination-next></span></a>
                </li>
              </ul>
            </div>
          </div>
        </td>
      </tr>
      </tfoot>
    </table>
  </div>
</template>