<script setup>
import { ref } from 'vue';
let props = defineProps({
  ratio: {
    type: Number,
    default: 7
  }
})

let ratio = ref(props.ratio);
</script>



<template>
    <div class="uk-flex uk-flex-column uk-width-auto  uk-position-center">
        <!-- L<span class="cog" uk-icon="icon: cog; ratio: 2"></span>ading -->
        <span class="cog uk-width-1-1" :uk-icon="'icon: cog; ratio: ' + ratio + ';'"></span>
    </div>
</template>