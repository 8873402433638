import {createRouter, createWebHistory} from 'vue-router';
import {useAuthStore} from '../stores/auth.store.js';
import {useErrorCheckStore} from '../stores/errorCheck.store.js';
import UIkit from "uikit";
// The static pages
import TheHomePage from '../views/TheHomePage.vue';
import Profile from '../views/Profile.vue';
import Projects from '../views/Projects.vue';
import Invoice from '../views/InvoiceList.vue';
import AQ from '../views/AQ.vue';
import UnitInformation from '../views/UnitInformation.vue';
import TheUserList from '../views/TheUserList.vue';
import AQDetails from '../views/AQDetails.vue';
import Login from "../Components/Login.vue";
import CreateInvoice from "../views/CreateInvoice.vue";

function createRoutes() {
    let commonRoutes = [
        {name: 'home', path: '/', component: TheHomePage},
        {name: 'Profile', path: '/Profile', component: Profile},
        {name: 'projects', path: '/projects', component: Projects},
        {name: 'invoice', path: '/invoice', component: Invoice},
        {name: 'AQ', path: '/AQ', component: AQ},
        {name: 'unitInformation', path: '/unit-information', component: UnitInformation},
        {name: 'users-list', path: '/users-list', component: TheUserList},
        {name: 'aqDetails', path: '/AQ/details', component: AQDetails},
        {name: 'BELogin', path: '/belogin', params: {username: "", foo: ""},component: Login},
        {name: 'CreateInvoice', path: '/CreateInvoice', component: CreateInvoice},
    ];
    return commonRoutes
}

export const router = createRouter({
    history: createWebHistory(),
    linkActiveClass: 'uk-active',
    routes: createRoutes(),
});
router.beforeEach(async (to) => {
    // clean up any errors when navigating to a new page
    if (useErrorCheckStore().errorList.length > 0) {
        useErrorCheckStore().clearErrorList();
    }
    if (useErrorCheckStore().successList.length > 0) {
        useErrorCheckStore().clearSuccessList();
    }

    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/', '/belogin'];

    const authRequired = !publicPages.includes(to.path);
    // const authRequired = false;
    const authStore = useAuthStore();
    // Secured Page
    if (authRequired) {
        // No user go home. Preserve where we came from    
        if (!authStore.user) {
            // log("Root GROOT");
            authStore.returnUrl = to.fullPath;
            return '/';
        } else {
            authStore.refreshToken();
        }
    }
});