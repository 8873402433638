<script setup>
import {onBeforeMount, onMounted, ref, computed, watch} from 'vue';
import {useAuthStore} from '../stores/auth.store.js';
import {useAQStore} from '../stores/aq.store.js';
import {useErrorCheckStore} from '../stores/errorCheck.store.js';
import {authHeader} from '../router/authheader.js';
import AQObject from './AQObject.vue';
import Loading from './Loading.vue';
import Search from './Search.vue';
import InvoiceObject from './InvoiceObject.vue';
import uikit from 'uikit';
import {router} from '../router/router.js';

const props = defineProps({
  modelValue: {},
  ID: {
    type: String,
  },
  Headers: {
    type: Array,
  },
  MobileHeaders: {
    type: Array,
  },
  ToggleKey: {
    type: String,
  },
  FetchKey: {
    type: String,
  },
  EndPoint: {
    type: String,
  },
  Data: {
    type: Object,
  },
  Selected: {
    type: Boolean,
    default: false
  },
  DataType: {
    type: String
  },
  Close: {
    type: Function
  }
})

const myID = ref('');
const baseUrl = process.env.APIURL + props.EndPoint;
const aqData = ref({});
const store = useAQStore();
const tableHeaders = ref([]);
const aqObjects = ref([]);
const toggled = ref(false);
const fetching = ref(false);
const fetched = ref(false);
const showOptions = ref(false);
const statusOptions = ref([]);
const locationOptions = ref([]);
const currentLocationFilter = ref('all');
const currentStatusFilter = ref('all');
const localKeys = ref(['Object ID', 'Unit #', 'UNIT #', 'Tag #', 'ASSET #', 'Asset #', 'Location', 'Status']);
const excludeKeys = ref(['InvoiceID', 'grpSeq', 'locSeq', 'iSeq']);
const currentFilter = ref('all');
const filterKey = ref('Status');
const objectSearch = ref('');


// pagination variables
const paginate = ref(true);
const sortedHeaders = ref([]);
const sortedData = ref([]);
const currentPage = ref(1);
const rowsPerPage = ref(51); // change this to adjust how many objects are shown per page
const startItemNum = ref(0);
const endItemNum = ref(rowsPerPage.value);
const sorting = ref(false);
const currentDataLength = ref(0);
let hideNote = localStorage.getItem('hideNote');

const invoiceObjects = ref([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12]);

let selectAll = ref(false);

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

onBeforeMount(async () => {
  // console.log("AQ Card before mount");
  // await fetchAQ(props.AQData['PO#']);
  myID.value = props.ID;

  if (props.DataType) {
    // console.log("Data type: ", props.DataType);
  }
})

onMounted(() => {
  // console.log("AQ Card mounted");
  // console.log("AQ Card props: ", props.Data);
})

const getID = (item) => {
  return String(item);
}

const fetchAQ = async () => {
  // console.log("fetchAQ, Selected at click: ", props.selected);
  // console.log("fetchAQ, fetched?: ", fetched.value);
  // console.log("fetchAQ baseURL ", baseUrl);
  if (!fetched.value) {
    fetching.value = true;
    const user = useAuthStore().user;
    // const currentValue = PO;
    const url = baseUrl;
    // console.log("Fetch Key: ", props.fetchKey);
    const keys = ["ID", props.FetchKey]
    let temp = {};
    keys.forEach((key) => {
      temp[key] = props.Data[key];
    })
    temp.ID = user.uID;
    // console.log("Temp: ", temp);
    // const url = baseUrl + "/GetByPOUnsorted";
    let returnValue = "";
    let call = temp;
    const response = await fetch(url, {
      method: 'POST',
      headers: authHeader(url),
      body: JSON.stringify(call)
    });

    let checkResponse = useErrorCheckStore().checkStatus(response);
    if (checkResponse) {
      returnValue = await response.json();
      // console.log("IC getByPO Response: ", returnValue)
      let checkReturnValue = useErrorCheckStore().checkStatusJSON(returnValue, false, true);
      // let checkReturnValue = useErrorCheckStore().checkStatusJSON(returnValue);
      console.log("fetch aq checkReturnValue ", checkReturnValue);
      if (checkReturnValue) {
        const data = returnValue.Data;
        // map the data
        if (data.AQObject) {
          aqData.value = data.AQObject // aqData in the response
        }

        // console.log("AQ Data: ", aqData.value);
        tableHeaders.value = data.Headers; // tableHeaders in the response
        aqObjects.value = data.Objects; // objects in the response
        if (data.InvoiceDetailsList) {

          if (data.GroupCharges) {
            aqObjects.value = handleInvoiceList(data.InvoiceDetailsList, data.GroupCharges);
          } else {
            let groupCharges = [];
            aqObjects.value = handleInvoiceList(data.InvoiceDetailsList, groupCharges);
          }
          // assign the unique keys to the objects for searching
          // aqObjects.value.forEach((item) => {
          //   item.UniqueIDs.forEach((id) => {
          //     item[id.key] = id.value;
          //   })
          // })
        }
        statusOptions.value = data.StatusValues; // status options in the response
        locationOptions.value = data.LocationValues;
        // console.log("Status options: ", statusOptions.value);
        // console.log("Table Data value: ", data)
        // console.log("current props", props);
        fetching.value = false;
        fetched.value = true;
      }
    }
    // return data;
  }
}

const handleInvoiceList = (list, groupCharges) => {
  list.forEach((item) => {
    item.UniqueIDs.forEach((id) => {
      item[id.key] = id.value;
    })
  })
  // console.log("New list: ", list);
  if (groupCharges && groupCharges.length > 0) {
    groupCharges.forEach((charge) => {
      charge['ChargeType'] = charge.ChargeType;
      charge['ChargeAmount'] = charge.ChargeAmount;
      list.push(charge);
    })
  }
  return list;
}

const checkSearch = (value) => {
  changePage(1);
  if (String(value).toLocaleLowerCase().includes(props.modelValue.toLocaleLowerCase())) {
    return true;
  }
  return false;
}


const _data = computed(() => {
  let start = 0;
  startItemNum.value = 1;
  let end = 0;
  start = (currentPage.value - 1) * rowsPerPage.value;
  startItemNum.value = start + 1;
  end = currentPage.value * rowsPerPage.value;

  if (currentFilter.value === 'all') {

    // if search has a value
    if (objectSearch.value) {
      currentStatusFilter.value = 'all';
      currentLocationFilter.value = 'all';
      let newData = [];
      aqObjects.value.forEach((row) => {
        for (const [key, value] of Object.entries(row)) {
          // if (localKeys.value.includes(key)) {
          // console.log("Checking row: ", row, ' for ', objectSearch.value);
          if (String(value).toLocaleLowerCase().includes(objectSearch.value.toLocaleLowerCase())) {
            // console.log("Found: ", value, ' in ', row, ' for ', objectSearch.value, " key: ", key);
            if (!newData.includes(row)) {
              newData.push(row);
            }
          }
          // }
        }
      });
      currentDataLength.value = newData.length;
      newData = newData.slice(start, end);
      endItemNum.value = start + newData.length;
      return newData;
    }

    let newTable = [...aqObjects.value];
    currentDataLength.value = newTable.length;
    newTable = newTable.slice(start, end);
    endItemNum.value = start + newTable.length;


    return newTable;
  }
  let filteredData = [...aqObjects.value]
  filteredData = filteredData.filter((item) => {
    if (item[filterKey.value] === currentFilter.value) {
      return true;
    }
    return false;
  });

  currentDataLength.value = filteredData.length;
  filteredData = filteredData.slice(start, end);
  endItemNum.value = start + filteredData.length;
  return filteredData;

});

const totalPages = computed(() => {
  return Math.ceil(currentDataLength.value / rowsPerPage.value);
});

const changePage = (page) => {
  if (page > 0 && page <= totalPages.value) {
    currentPage.value = page;
  }
//   currentPage.value = page;
}

const resetPage = () => {
  currentPage.value = 1;
}

const formatKey = ((key) => {
  key = key.replace(/ /g, '-');
  key = key.replace(/\//g, '');
  key = key.replace(/&/g, 'and');
  return key;
})

const setFilter = (filter, category) => {
  changePage(1);
  objectSearch.value = '';
  // console.log("Setting filter: ", filter, " for category: ", category);
  currentFilter.value = filter;
  if (category == 'Status') {
    currentLocationFilter.value = 'all';
  }
  if (category == 'Location') {
    currentStatusFilter.value = 'all';
  }
  filterKey.value = category;
}

const goToInvoicing = () => {
  router.push({ name: 'invoice' });
}

watch(objectSearch, (newVal, oldVal) => {
  if (oldVal == '') {
    currentFilter.value = 'all';
  }
  changePage(1);
})

const getToggleString = () => {
  // console.log("Toggle key: ", props.toggleKey);
  // console.log("Data: ", props.Data);
  // console.log("myID: ", myID.value);
  // return "jimmy";
  return 'target: #' + myID.value + props.ToggleKey + '; cls: card-open; ';
}

const genInstallCardClassString = (key) => {
  let generatedClass = 'uk-text-center';

  generatedClass += ' uk-width-' + key.ratio + '-4';

  return generatedClass;
}

const checkIfInvoiceRoute = () => {
  if (router.currentRoute.value.name === 'invoice') {
    return true;
  }
  return false;
}

const selectObject = (index) => {
  // console.log("Index from child: ", index);
  return index;
}

const selectAllObjects = () => {
  selectAll.value = !selectAll.value;
}

const rememberHide = () => {
  localStorage.setItem('hideNote', true);
}

const toggleOptions = () => {
  showOptions.value = !showOptions.value;
}


const removeExcluded = () => {
  let goodKeys = [];
  let keys = Object.keys(props.Data);
  keys.forEach((key) => {
    if (!excludeKeys.value.includes(key)) {
      goodKeys.push(key);
    }
  })
  return goodKeys;
}

const getHeaderText = (key) => {
  // console.log("Key: ", key);
  if (key.toLocaleLowerCase() == 'invoice amount') {
    let decimal = props.Data[key];
    decimal = formatter.format(decimal);
    // console.log("Decimals: ", decimal);
    return decimal;
  }
  return props.Data[key];
}

const goToInvoiceList = () => {
  // let status = aqData.value.AQStatus;
  let tempAQObject = aqData.value;
  // delete tempAQObject['Status'];
  // tempAQObject['Status'] = status;
  // console.log("Temp AQ Object: ", tempAQObject);
  // localStorage.setItem('AQObject', tempAQObject);
  useAQStore().setCurrentAQObject(JSON.stringify(tempAQObject));
  router.push({ name: 'invoice' });
}

const goToCreateInvoice = () => {
  // let status = aqData.value.AQStatus;
  let tempAQObject = aqData.value;
  // delete tempAQObject['Status'];
  // tempAQObject['Status'] = status;
  // console.log("Temp AQ Object: ", tempAQObject);
  // localStorage.setItem('AQObject', tempAQObject);
  useAQStore().setCurrentAQObject(JSON.stringify(tempAQObject));
  router.push({ name: 'CreateInvoice' });
}
</script>

<template>
  <div class="aq-card uk-card uk-flex uk-flex-column">
    <!-- <button class="uk-flex uk-button uk-button-default" type="button" :uk-toggle="'target: #AQ-' + AQData['AQ'] + '; cls: card-open; '"
    > -->
    <div
        :class="{'aq-toggle  uk-button uk-padding-small uk-padding-remove-vertical' : {},
            'uk-button-default uk-background-default' : !props.Selected,
            'uk-button-primary' : props.Selected}"
        :uk-toggle="getToggleString()"
        @click="fetchAQ(props.Data[props.FetchKey])">
      <div :class="'project-button-large uk-flex uk-flex-between uk-text-nowrap'">
        <div v-for="(key, index) in props.Headers" :class="'uk-visible@m uk-text-center uk-width-'+ key.ratio + '-4 '">
          <!-- <span :class="{ 'search-result' : modelValue && checkSearch(props.Data[key.key])}">{{ props.Data[key.key] }}</span> -->
          <span :class="{ 'search-result' : modelValue && checkSearch(props.Data[key.key])}">{{
              getHeaderText(key.key)
            }}</span>
        </div>
        <div v-for="(key, index) in props.MobileHeaders"
             :class="'uk-hidden@m uk-text-center uk-text-small uk-padding-small uk-padding-remove-horizontal uk-width-'+ key.ratio + '-4 '">
          <span :class="{ 'search-result' : modelValue && checkSearch(props.Data[key.key])}">{{
              getHeaderText(key.key)
            }}</span>
        </div>
      </div>
    </div>

    <div :id="myID + props.ToggleKey"
         class="card-contents uk-flex uk-flex-center card-default uk-width-1-1 uk-align-center uk-margin-remove uk-position-relative"
         uk-grid>
      <div class="aq-data uk-width-1-1 uk-width-1-4@l uk-background-primary uk-text-muted uk-padding-remove">
        <div
            class="aq-info uk-flex uk-flex-between uk-padding-small uk-padding-remove-top uk-margin-bottom uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-1-1@l uk-align-center uk-margin-remove"
            uk-grid>
          <div v-for="key in removeExcluded()"
               class="aq-info-item uk-flex uk-flex-column uk-padding-small uk-padding-remove-bottom uk-margin-remove"
               uk-height-match=".options-objects">
            <div v-if="!excludeKeys.includes(key)" class="uk-flex uk-flex-column uk-text-break">
                <span class="uk-text-bold">
                    {{ key }}: 
                </span>
              <span
                  :class="{'search-result' : modelValue && checkSearch(props.Data[key]), 'uk-margin-remove uk-padding-small@l uk-padding-remove-vertical' : key}">
                    <span v-if="key.toLocaleLowerCase().includes('amount')"></span>{{ getHeaderText(key) }}
                </span>
            </div>
            
          </div>
        </div>

      </div>
      <div v-if="showOptions && !checkIfInvoiceRoute()" id="UKDropdown" class="options-container">
        <div class="options uk-background-primary uk-margin-remove uk-padding uk-position-relative"
             :uk-sticky="'offset: 100; end: #' + myID + props.ToggleKey">
          <button class="uk-light uk-position-top-right close-options" @click="toggleOptions">X</button>
          <ul class="uk-list uk-flex uk-width-1-1 uk-margin-remove" uk-grid>
            <div v-if="!checkIfInvoiceRoute()"
                 class="uk-width-1-1 uk-width-1-2@m uk-width-expand@m uk-flex uk-flex-column uk-padding-remove">
              <div class="uk-light uk-padding-small">
                <li class="uk-text-lead">Status Filters</li>
                <li class="uk-link">
                  <select class="uk-select uk-width-expand height-40" name="locationSelection"
                          :id="'LocationSelection' + props.Data.AQ" v-model="currentStatusFilter"
                          @change="setFilter(currentStatusFilter, 'Status')">
                    <option value="all" class="uk-background-muted uk-text-secondary">SHOW ALL</option>
                    <option v-for="option in statusOptions" class="uk-background-muted uk-text-secondary"
                            :value="option">{{ option }}
                    </option>
                  </select>
                </li>
              </div>
              <div class="uk-light uk-padding-small uk-padding-remove-top">
                <li class="uk-text-lead">Location Filters</li>
                <li class="uk-link">
                  <select class="uk-select uk-width-expand height-40" name="locationSelection" id="LocationSelection"
                          v-model="currentLocationFilter" @change="setFilter(currentLocationFilter, 'Location')">
                    <option value="all" class="uk-background-muted uk-text-secondary">SHOW ALL</option>
                    <option v-for="location in locationOptions" class="uk-background-muted uk-text-secondary"
                            :value="location">{{ location }}
                    </option>
                  </select>
                  <!-- <a class="" @click.stop.prevent="setFilter(option, 'Location')" href="#" uk-toggle="target: #UKDropdown">{{ option }}</a> -->
                </li>
              </div>
            </div>
            <div class="uk-height-expand uk-visible@m uk-padding-remove">
              <hr v-if="!checkIfInvoiceRoute()"
                  class="uk-divider-vertical uk-margin-medium-left uk-margin-medium-right uk-background-default uk-height-1-1">
            </div>
            <div
                class="uk-width-1-1 uk-width-1-2@m uk-width-expand@m uk-padding-remove-left uk-margin-left@m uk-light uk-height-auto uk-margin-remove-top">
              <div class="uk-padding-small">
                <li class="uk-text-lead">Invoicing</li>
                <li v-if="!checkIfInvoiceRoute()" class="uk-position-relative uk-width-1-1 uk-padding-small-bottom">
                  <a class="invoice-link uk-input uk-width-expand uk-text-center uk-width-1-1" @click="goToInvoiceList"
                     uk-toggle>View Invoices</a>
                </li>
                <li uk-toggle="target: #UKDropdown" class="uk-margin-top height-40">
                  <a @click="goToCreateInvoice" class="invoice-link uk-link uk-input uk-text-center">Create Invoice</a>
                </li>
              </div>
            </div>
            <!-- <li class="uk-nav-divider uk-margin-top"></li> -->
          </ul>

        </div>
      </div>
      <div
          class="options-objects uk-width-1-1 uk-width-3-4@l uk-flex uk-flex-column uk-flex-between uk-padding-small uk-padding-remove-vertical uk-padding-remove-right">
        <div
            class="uk-flex uk-flex-between uk-padding uk-padding-remove-vertical uk-padding-remove-right uk-child-width-1-1 uk-child-width-1-3@s"
            uk-grid>
          <AgileButton v-if="!checkIfInvoiceRoute()" @click="toggleOptions" class="status-filter"
                       :icon="'triangle-down'">Options
          </AgileButton>
          <Search :ID="myID + '-Search'"
                  class="object-search uk-float-right uk-height-1-1 uk-margin-remove uk-padding-remove uk-width-1-1 uk-width-1-3@s"
                  :Shadow="false"
                  v-model="objectSearch"/>
        </div>
        <div class="aq-objects uk-flex uk-width-stretch uk-position-relative uk-margin-small-top">
          <div v-if="fetching" class="uk-flex uk-flex-center uk-flex-middle">
            <Loading/>
          </div>
          <div class="uk-width-1-1">
            <!-- This is where the object cards will go-->
            <ul v-if="aqObjects" class="uk-list uk-height-1-1 uk-width-1-1" :class="{'uk-flex grid' : !props.DataType}">
              <li v-for="item in _data" :key="item" class="uk-margin-small uk-margin-remove-top" :class="{'' : props.DataType}">
                <InvoiceObject v-if="props.DataType && props.DataType == 'Invoice'" v-model="props.modelValue"
                               :filter="objectSearch" :data="item"/>
                <AQObject v-if="!props.DataType" class="" v-model="props.modelValue"
                          :data="item" :keys="localKeys" :filter="objectSearch"/>
              </li>
            </ul>
          </div>
        </div>

        <div class="pagination-controls uk-margin-top uk-background-primary uk-padding-small">
          <div class="uk-flex uk-flex-between uk-light">
            <div class="uk-visible@s ">
              <span class="uk-margin-small-right">
                  {{ startItemNum }} - {{ endItemNum }}
              </span>
              <span>( of {{ currentDataLength }} )</span>
            </div>
            <div class="uk-text-center uk-width-1-1 uk-width-auto@s">
              <ul class="uk-pagination uk-margin-remove uk-flex uk-flex-between uk-position-z-index">
                <li>
                  <a class="uk-padding-remove-bottom " href="#"
                     @click.stop.prevent="changePage(currentPage - 1)">
                    <span uk-pagination-previous></span>
                  </a>
                </li>
                <li v-for="page in totalPages" :key="page"
                    :class="{'active' : page === currentPage, 'not-active' : page != currentPage}">

                  <a class="uk-padding-remove-bottom uk-text-middle" href="#"
                     @click.stop.prevent="changePage(page)">{{ page }}</a>
                </li>
                <li>
                  <a class="uk-padding-remove-bottom " href="#"
                     @click.stop.prevent="changePage(currentPage + 1)">
                    <span uk-pagination-next></span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>