<script setup>
import { router } from '../router/router';
import {onMounted, ref, watch} from 'vue';
import Cookies from './Cookies.vue';
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import {useRoute} from "vue-router";

const year = ref(new Date().getFullYear());
const address1 = process.env.Address1;
const address2 = process.env.Address2;
const address3 = process.env.Address3;
const phone = process.env.Phone;
const fax = process.env.Fax;
const LowenCorpURL = process.env.LowenCorpURL;
const LowenColorGraphicsURL = process.env.LowenColorGraphicsURL;
const LowenColorGraphicsSitemap = process.env.LowenColorGraphicsSitemap
const LowenColorGraphicsBlog = process.env.LowenColorGraphicsBlog
const LowenColorGraphicsAbout = process.env.LowenColorGraphicsAbout
const LowenColorGraphicsServices = process.env.LowenColorGraphicsServices
const LowenColorGraphicsAgile = process.env.LowenColorGraphicsAgile
const LowenAgile = process.env.LowenAgile
const LowenSign = process.env.LowenSign
const LowenCertified = process.env.LowenCertified
const LowenTradesource = process.env.LowenTradesource
const LowenInstallers = process.env.LowenInstallers
const navigate = (page) => {
    if (page) {
        router.push(page);
    }
}

</script>

<template>
    <!-- I feel like maybe a lot of these links can go away? -->
        <Cookies />
    <section class="Footer uk-flex uk-flex-column uk-margin-medium-top uk-box-shadow-medium uk-padding">
        <div class="footer-content-container uk-flex uk-flex-between uk-text-center uk-text-left@s uk-margin-small-bottom uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-4@m" uk-grid>
            <div class="footer-col-1 uk-flex uk-flex-column ">
                <p class="uk-margin-remove">{{address1}}</p>
                <p class="uk-margin-remove">{{address2}}</p>
                <p class="uk-margin-remove">{{address3}}</p>
                <p class="uk-margin-remove">PH | {{phone}}</p>
                <p class="uk-margin-remove">FX | {{fax}}</p>
                <a class="uk-text-secondary uk-margin-remove" target="_blank" :href="LowenColorGraphicsURL">www.lowencg.com</a>
            </div>
            <div class="footer-col-2 uk-flex uk-flex-column" style="border-left: 1px solid lightgrey;">
                <a class="uk-text-secondary" href="#" @click="navigate('projects')">Home</a>
                <a class="uk-text-secondary" target="_blank" :href="LowenColorGraphicsSitemap">Sitemap</a>
                <a class="uk-text-secondary" target="_blank" :href="LowenCorpURL">Lowen Corporation</a>
                <a class="uk-text-secondary" :href="LowenInstallers">Installers Login</a>
                <a class="uk-text-secondary" target="_blank" :href="LowenColorGraphicsURL + '#contact'">Contact Us</a>
                <a class="uk-text-secondary" target="_blank" :href="LowenColorGraphicsBlog">CG News Hub</a>
            </div>
            <div class="footer-col-3 uk-flex uk-flex-column" style="border-left: 1px solid lightgrey;">
                <a class="uk-text-secondary" target="_blank" :href="LowenColorGraphicsAbout">About Lowen Color Graphics</a>
                <a class="uk-text-secondary" target="_blank" :href="LowenColorGraphicsAbout">3M Partnership</a> <!--Do we need this? Goes to cg about-->
                <a class="uk-text-secondary" target="_blank" :href="LowenColorGraphicsServices">Installation Management</a>
                <a class="uk-text-secondary" target="_blank" :href="LowenColorGraphicsServices">Services</a>
                <a class="uk-text-secondary" target="_blank" :href="LowenColorGraphicsAgile">Case Studies</a>
                <a class="uk-text-secondary" target="_blank" :href="LowenAgile">eData Login</a>
            </div>
            <div class="footer-col-4 uk-flex uk-flex-column" style="border-left: 1px solid lightgrey;">
                <a class="uk-text-secondary" target="_blank" :href="LowenSign">Lowen Sign Company</a>
                <a class="uk-text-secondary" target="_blank" :href="LowenCertified">Lowen Certified</a>
                <a class="uk-text-secondary" target="_blank" :href="LowenTradesource">Lowen Tradesource</a>
            </div>
        </div>
  <!-- <div class="footer-logo-container uk-flex uk-flex-center uk-child-width-1-1 uk-child-width-auto@s uk-margin-bottom" uk-grid>
            <a class="uk-text-center" target="_blank" :href="LowenCorpURL"><img class="footer-logo" src="../../public/CorpLogo.png"/></a>
            <a class="uk-text-center" target="_blank" :href="LowenColorGraphicsURL"><img class="footer-logo" src="../../public/LowenCGLogo.svg"/></a>
            <a class="uk-text-center" target="_blank" :href="LowenSign"><img class="footer-logo" src="../../public/lowenSign.svg"/></a>
            <a class="uk-text-center" target="_blank" :href="LowenCertified"><img class="footer-logo" src="../../public/CertifiedLogo.png"/></a>
            <a class="uk-text-center" target="_blank" :href="LowenTradesource"><img class="footer-logo" src="../../public/tradesourcelogo.png"/></a>
        </div>-->
        <div class="footer-copyright-container uk-margin-remove-top uk-align-center uk-margin-remove-bottom">
            <p>Copyright <span>&copy;</span> {{ year }} Lowen Corporation. All Rights Reserved.</p>
        </div>
    </section>
</template>